import React from 'react';

// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';
import { LoadingContext } from 'context/loadingContext';
import Header from 'components/molecules/Header';
import ModalContainer from 'components/molecules/ModalContainer';

import Button from 'components/atoms/Button';

import AdminForm from 'components/organisms/AdminForm';

import topNavData from 'nav.json';

import PermissionsForm from 'components/organisms/PermissionsForm';
import RolesForm from 'components/organisms/RolesForm';

import { useParams } from 'react-router-dom';
import backendService from 'services/backendService';

import Permissions from 'helpers/defaultPermission.json';
import defaultRoles from 'helpers/defaultRoles';
import Toast from 'components/molecules/Toast';
import { AuthContext } from 'context/authContext';
import Select from 'components/atoms/Select';
import ShiftsForm from 'components/organisms/ShiftsFrom';
import { useContextHook } from 'use-context-hook';

function TopBar() {
  const { isLoading } = useContextHook(LoadingContext, {
    isLoading: 1,
  });
  const { hasPermission, gmt, setGMT } = useContextHook(AuthContext, {
    hasPermission: 1,
    gmt: 1,
    setGMT: 1,
  });
  // eslint-disable-next-line prefer-const
  let { view: title, child } = useParams();
  const oldTitle = title;
  if (child) {
    title = child;
  }
  // eslint-disable-next-line prefer-const
  let { buttons = [], subNav = [] } =
    topNavData.find(({ file }) => file === title) ?? topNavData.find(({ file }) => file === oldTitle);
  if (subNav?.length) {
    const { buttons: subNavButtons } = subNav.find(({ file }) => file === title) ?? { buttons: [] };
    buttons = [...buttons, ...subNavButtons];
  }
  const restorePermissions = async () => {
    try {
      const res = await backendService.restorePermissions({ permissions: Permissions, roles: defaultRoles() });
      if (res) {
        Toast({
          type: 'success',
          message: 'Permissions restored successfully',
        });
      }
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex?.message,
      });
    }
  };
  if (title.includes('b3')) {
    title = title.replace('b3', 'Genius Feeds 1');
  } else if (title.includes('b4')) {
    title = title.replace('b4', 'Genius Feeds 2');
  }else if (title.includes('bwin')) {
    title = title.replace('bwin', 'Genius Feeds 3');
  }else if (title.includes('1xbet')) {
    title = title.replace('1xbet', 'Genius Feeds 4');
  }
  return (
    <>
      <Header title={title.split('-').join(' ')}>
        {isLoading ? (
          <Skeleton rectangle height={40} width={131} css="border-radius:8px !important;" />
        ) : (
          <>
            {hasPermission('admins.create') && buttons.includes('admins.create') && (
              <ModalContainer
                lg
                title="Create Admin"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create Admin</span>
                  </Button>
                )}
                content={({ onClose }) => <AdminForm onClose={onClose} />}
              />
            )}

            {hasPermission('permissions.create') && buttons.includes('permissions.create') && (
              <ModalContainer
                lg
                title="Create Permission"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create Permission</span>
                  </Button>
                )}
                content={({ onClose }) => <PermissionsForm onClose={onClose} />}
              />
            )}

            {hasPermission('roles.create') && buttons.includes('roles.create') && (
              <ModalContainer
                lg
                title="Create Role"
                btnComponent={({ onClick }) => (
                  <Button
                    type="primary"
                    onClick={onClick}
                    iconMobile
                    prefix={<i className="material-icons-outlined">add</i>}>
                    <span className="text">Create Role</span>
                  </Button>
                )}
                content={({ onClose }) => <RolesForm onClose={onClose} />}
              />
            )}

            {hasPermission('permissions.restore') && buttons.includes('permissions.restore') && (
              <Button
                type="outline"
                onClick={restorePermissions}
                iconMobile
                prefix={<i className="material-icons-outlined">restore</i>}>
                <span className="text">Restore Permissions</span>
              </Button>
            )}
            {buttons.includes('selectGMT') && (
              <Select
                noMargin
                sm
                options={[
                  { value: '-12', label: 'GMT -12' },
                  { value: '-11', label: 'GMT -11' },
                  { value: '-10', label: 'GMT -10' },
                  { value: '-9', label: 'GMT -9' },
                  { value: '-8', label: 'GMT -8' },
                  { value: '-7', label: 'GMT -7' },
                  { value: '-6', label: 'GMT -6' },
                  { value: '-5', label: 'GMT -5' },
                  { value: '-4', label: 'GMT -4' },
                  { value: '-3', label: 'GMT -3' },
                  { value: '-2', label: 'GMT -2' },
                  { value: '-1', label: 'GMT -1' },
                  { value: '0', label: 'GMT 0' },
                  { value: '1', label: 'GMT +1' },
                  { value: '2', label: 'GMT +2' },
                  { value: '3', label: 'GMT +3' },
                  { value: '4', label: 'GMT +4' },
                  { value: '5', label: 'GMT +5' },
                  { value: '6', label: 'GMT +6' },
                  { value: '7', label: 'GMT +7' },
                  { value: '8', label: 'GMT +8' },
                  { value: '9', label: 'GMT +9' },
                  { value: '10', label: 'GMT +10' },
                  { value: '11', label: 'GMT +11' },
                  { value: '12', label: 'GMT +12' },
                ]}
                value={gmt}
                onChange={({ target: { value } }) => {
                  setGMT(value);
                }}
                css={`
                  width: 150px;
                `}
              />
            )}
          </>
        )}

        {hasPermission('shifts.create') && buttons.includes('shifts.create') && (
          <ModalContainer
            lg
            title="Create Shift"
            btnComponent={({ onClick }) => (
              <Button
                type="primary"
                onClick={onClick}
                iconMobile
                prefix={<i className="material-icons-outlined">add</i>}>
                <span className="text">Create Shift</span>
              </Button>
            )}
            content={({ onClose }) => <ShiftsForm onClose={onClose} />}
          />
        )}
      </Header>
    </>
  );
}

export default TopBar;
