/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import 'styled-components/macro';
import { TooltipContainer, TooltipBtn, StyledTooltip } from './Tooltip.styles';

function Tooltip({ delay, children, title, width, type, cssC, place = 'top' }) {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  let timeout;

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, delay || 400);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  useEffect(() => {
    setHeight(ref?.current?.clientHeight);
  }, []);

  return (
    <TooltipContainer onMouseEnter={showTip} onMouseLeave={hideTip} css={cssC}>
      <TooltipBtn onClick={hideTip}>{children}</TooltipBtn>
      <StyledTooltip place={place} type={type} width={width} ref={ref} height={height} active={active}>
        {title}
      </StyledTooltip>
    </TooltipContainer>
  );
}

export default Tooltip;
