export default [
  {
    i: 1,
    n: {
      english: 'Colombia',
      persian: 'کلمبیا',
      turkish: 'Kolombiya',
      russian: 'Колумбия',
    },
  },
  {
    i: 2,
    n: {
      english: 'Montenegro',
      persian: 'مونته نگرو',
      turkish: 'Karadağ',
      russian: 'Черногория',
    },
  },
  {
    i: 3,
    n: {
      english: 'Estonia',
      persian: 'استونی',
      turkish: 'Estonya',
      russian: 'Эстония',
    },
  },
  {
    i: 4,
    n: {
      english: 'South Korea',
      persian: 'کره جنوبی',
      turkish: 'Güney Kore',
      russian: 'Южная Корея',
    },
  },
  {
    i: 5,
    n: {
      english: 'Switzerland',
      persian: 'سوئیس',
      turkish: 'İsviçre',
      russian: 'Швейцария',
    },
  },
  {
    i: 6,
    n: {
      english: 'Finland',
      persian: 'فنلاند',
      turkish: 'Finlandiya',
      russian: 'Финляндия',
    },
  },
  {
    i: 7,
    n: {
      english: 'England',
      persian: 'انگلستان',
      turkish: 'İngiltere',
      russian: 'Англия',
    },
  },
  {
    i: 8,
    n: {
      english: 'Europe',
      persian: 'اروپا',
      turkish: 'Avrupa',
      russian: 'Европа',
    },
  },
  {
    i: 9,
    n: {
      english: 'New Zealand',
      persian: 'نیوزلند',
      turkish: 'Yeni Zelanda',
      russian: 'Новая Зеландия',
    },
  },
  {
    i: 10,
    n: {
      english: 'Romania',
      persian: 'رومانی',
      turkish: 'Romanya',
      russian: 'Румыния',
    },
  },
  {
    i: 11,
    n: {
      english: 'Guatemala',
      persian: 'گواتمالا',
      turkish: 'Guatemala',
      russian: 'Гватемала',
    },
  },
  {
    i: 12,
    n: {
      english: 'France',
      persian: 'فرانسه',
      turkish: 'Fransa',
      russian: 'Франция',
    },
  },
  {
    i: 13,
    n: {
      english: 'Costa Rica',
      persian: 'کاستاریکا',
      turkish: 'Kosta Rika',
      russian: 'Коста-Рика',
    },
  },
  {
    i: 14,
    n: {
      english: 'Cyprus',
      persian: 'قبرس',
      turkish: 'Güney Kıbrıs',
      russian: 'Кипр',
    },
  },
  {
    i: 15,
    n: {
      english: 'Indonesia',
      persian: 'اندونزی',
      turkish: 'Endonezya',
      russian: 'Индонезия',
    },
  },
  {
    i: 16,
    n: {
      english: 'Canada',
      persian: 'کانادا',
      turkish: 'Kanada',
      russian: 'Канада',
    },
  },
  {
    i: 17,
    n: {
      english: 'Georgia',
      persian: 'جورجیا',
      turkish: 'Gürcistan',
      russian: 'Грузия',
    },
  },
  {
    i: 18,
    n: {
      english: 'Northern Ireland',
      persian: 'ایرلند شمالی',
      turkish: 'Kuzey Irlanda',
      russian: 'Северная Ирландия',
    },
  },
  {
    i: 19,
    n: {
      english: 'India',
      persian: 'هند',
      turkish: 'Hindistan',
      russian: 'Индия',
    },
  },
  {
    i: 20,
    n: {
      english: 'Netherlands',
      persian: 'هلند',
      turkish: 'Hollanda',
      russian: 'Нидерланды',
    },
  },
  {
    i: 21,
    n: {
      english: 'Serbia',
      persian: 'صربستان',
      turkish: 'Sırbistan',
      russian: 'Сербия',
    },
  },
  {
    i: 22,
    n: {
      english: 'Rep. Ireland',
      persian: 'ایرلند',
      turkish: 'İrlanda',
      russian: 'Ирландия',
    },
  },
  {
    i: 23,
    n: {
      english: 'Hungary',
      persian: 'مجارستان',
      turkish: 'Macaristan',
      russian: 'Венгрия',
    },
  },
  {
    i: 24,
    n: {
      english: 'Mexico',
      persian: 'مکزیک',
      turkish: 'Meksika',
      russian: 'Мексика',
    },
  },
  {
    i: 25,
    n: {
      english: 'Scotland',
      persian: 'اسکاتلند',
      turkish: 'İskoçya',
      russian: 'Шотландия',
    },
  },
  {
    i: 26,
    n: {
      english: 'Cameroon',
      persian: 'کامرون',
      turkish: 'Kamerun',
      russian: 'Камерун',
    },
  },
  {
    i: 27,
    n: {
      english: 'Algeria',
      persian: 'الجزایر',
      turkish: 'Cezayir',
      russian: 'Алжир',
    },
  },
  {
    i: 28,
    n: {
      english: 'Lithuania',
      persian: 'لیتوانی',
      turkish: 'Litvanya',
      russian: 'Литва',
    },
  },
  {
    i: 29,
    n: {
      english: 'Paraguay',
      persian: 'پاراگوئه',
      turkish: 'Paraguay',
      russian: 'Парагвай',
    },
  },
  {
    i: 30,
    n: {
      english: 'Honduras',
      persian: 'هندوراس',
      turkish: 'Honduras',
      russian: 'Гондурас',
    },
  },
  {
    i: 31,
    n: {
      english: 'Belgium',
      persian: 'بلژیک',
      turkish: 'Belçika',
      russian: 'Бельгия',
    },
  },
  {
    i: 32,
    n: {
      english: 'United Kingdom',
      persian: 'انگلستان',
      turkish: 'Birleşik Krallık',
      russian: 'объединенное Королевство',
    },
  },
  {
    i: 33,
    n: {
      english: 'Croatia',
      persian: 'کرواسی',
      turkish: 'Hırvatistan',
      russian: 'Хорватия',
    },
  },
  {
    i: 34,
    n: {
      english: 'Sweden',
      persian: 'سوئد',
      turkish: 'İsveç',
      russian: 'Швеция',
    },
  },
  {
    i: 35,
    n: {
      english: 'Slovakia',
      persian: 'اسلواکی',
      turkish: 'Slovakya',
      russian: 'Словакия',
    },
  },
  {
    i: 36,
    n: {
      english: 'Brazil',
      persian: 'برزیل',
      turkish: 'Brezilya',
      russian: 'Бразилия',
    },
  },
  {
    i: 37,
    n: {
      english: 'Iceland',
      persian: 'ایسلند',
      turkish: 'İzlanda',
      russian: 'Исландия',
    },
  },
  {
    i: 38,
    n: {
      english: 'Australia',
      persian: 'استرالیا',
      turkish: 'Avustralya',
      russian: 'Австралия',
    },
  },
  {
    i: 39,
    n: {
      english: 'Austria',
      persian: 'اتریش',
      turkish: 'Avusturya',
      russian: 'Австрия',
    },
  },
  {
    i: 40,
    n: {
      english: 'Morocco',
      persian: 'مراکش',
      turkish: 'Fas',
      russian: 'Марокко',
    },
  },
  {
    i: 41,
    n: {
      english: 'Venezuela',
      persian: 'ونزوئلا',
      turkish: 'Venezuela',
      russian: 'Венесуэла',
    },
  },
  {
    i: 42,
    n: {
      english: 'Ukraine',
      persian: 'اوکراین',
      turkish: 'Ukrayna',
      russian: 'Украина',
    },
  },
  {
    i: 43,
    n: {
      english: 'Bulgaria',
      persian: 'بلغارستان',
      turkish: 'Bulgaristan',
      russian: 'Болгария',
    },
  },
  {
    i: 44,
    n: {
      english: 'Portugal',
      persian: 'کشور پرتغال',
      turkish: 'Portekiz',
      russian: 'Португалия',
    },
  },
  {
    i: 45,
    n: {
      english: 'Italy',
      persian: 'ایتالیا',
      turkish: 'İtalya',
      russian: 'Италия',
    },
  },
  {
    i: 46,
    n: {
      english: 'Vietnam',
      persian: 'ویتنام',
      turkish: 'Vietnam',
      russian: 'Вьетнам',
    },
  },
  {
    i: 47,
    n: {
      english: 'Germany',
      persian: 'آلمان',
      turkish: 'Almanya',
      russian: 'Германия',
    },
  },
  {
    i: 48,
    n: {
      english: 'North America',
      persian: 'آمریکای شمالی',
      turkish: 'Kuzey Amerika',
      russian: 'Северная Америка',
    },
  },
  {
    i: 49,
    n: {
      english: 'Spain',
      persian: 'اسپانیا',
      turkish: 'İspanya',
      russian: 'Испания',
    },
  },
  {
    i: 50,
    n: {
      english: 'World',
      persian: 'جهان',
      turkish: 'Dünya',
      russian: 'Мир',
    },
  },
  {
    i: 51,
    n: {
      english: 'Poland',
      persian: 'لهستان',
      turkish: 'Polonya',
      russian: 'Польша',
    },
  },
  {
    i: 52,
    n: {
      english: 'Andorra',
      persian: 'آندورا',
      turkish: 'Andorra',
      russian: 'андорра',
    },
  },
  {
    i: 53,
    n: {
      english: 'China',
      persian: 'چین',
      turkish: 'Çin',
      russian: 'Китай',
    },
  },
  {
    i: 54,
    n: {
      english: 'Israel',
      persian: 'اسرائيل',
      turkish: 'İsrail',
      russian: 'Израиль',
    },
  },
  {
    i: 55,
    n: {
      english: 'Malta',
      persian: 'مالت',
      turkish: 'Malta',
      russian: 'Мальта',
    },
  },
  {
    i: 56,
    n: {
      english: 'Norway',
      persian: 'نروژ',
      turkish: 'Norveç',
      russian: 'Норвегия',
    },
  },
  {
    i: 57,
    n: {
      english: 'Turkey',
      persian: 'بوقلمون',
      turkish: 'Türkiye',
      russian: 'Турция',
    },
  },
  {
    i: 58,
    n: {
      english: 'Argentina',
      persian: 'آرژانتین',
      turkish: 'Arjantin',
      russian: 'Аргентина',
    },
  },
  {
    i: 59,
    n: {
      english: 'Bolivia',
      persian: 'بولیوی',
      turkish: 'Bolivya',
      russian: 'Боливия',
    },
  },
  {
    i: 60,
    n: {
      english: 'Iran',
      persian: 'ایران',
      turkish: 'İran',
      russian: 'Иран',
    },
  },
  {
    i: 61,
    n: {
      english: 'Asia',
      persian: 'آسیا',
      turkish: 'Asya',
      russian: 'Азия',
    },
  },
  {
    i: 62,
    n: {
      english: 'South America',
      persian: 'آمریکای جنوبی',
      turkish: 'Güney Amerika',
      russian: 'Южная Америка',
    },
  },
  {
    i: 63,
    n: {
      english: 'Olympics',
      persian: 'المپیک',
      turkish: 'Olimpiyatlar',
      russian: 'Олимпийские игры',
    },
  },
  {
    i: 64,
    n: {
      english: 'South Africa',
      persian: 'آفریقای جنوبی',
      turkish: 'Güney Afrika',
      russian: 'Южная Африка',
    },
  },
  {
    i: 65,
    n: {
      english: 'Russia',
      persian: 'روسیه',
      turkish: 'Россия',
      russian: 'Россия',
    },
  },
  {
    i: 66,
    n: {
      english: 'El Salvador',
      persian: 'السالوادور',
      turkish: 'El Salvador',
      russian: 'Сальвадор',
    },
  },
  {
    i: 67,
    n: {
      english: 'Peru',
      persian: 'پرو',
      turkish: 'Peru',
      russian: 'Перу',
    },
  },
  {
    i: 68,
    n: {
      english: 'Uruguay',
      persian: 'اروگوئه',
      turkish: 'Uruguay',
      russian: 'Уругвай',
    },
  },
  {
    i: 69,
    n: {
      english: 'Greece',
      persian: 'یونان',
      turkish: 'Yunanistan',
      russian: 'Греция',
    },
  },
  {
    i: 70,
    n: {
      english: 'Philippines',
      persian: 'فیلیپین',
      turkish: 'Filipinler',
      russian: 'Филиппины',
    },
  },
  {
    i: 71,
    n: {
      english: 'Singapore',
      persian: 'سنگاپور',
      turkish: 'Singapur',
      russian: 'Сингапур',
    },
  },
  {
    i: 72,
    n: {
      english: 'North Macedonia',
      persian: 'مقدونیه شمالی',
      turkish: 'Kuzey Makedonya',
      russian: 'Северная Македония',
    },
  },
  {
    i: 73,
    n: {
      english: 'Japan',
      persian: 'ژاپن',
      turkish: 'Japonya',
      russian: 'Япония',
    },
  },
  {
    i: 74,
    n: {
      english: 'Wales',
      persian: 'ولز',
      turkish: 'Galler',
      russian: 'Уэльс',
    },
  },
  {
    i: 75,
    n: {
      english: 'Czech Republic',
      persian: 'جمهوری چک',
      turkish: 'Çek Cumhuriyeti',
      russian: 'Республика Чехия',
    },
  },
  {
    i: 76,
    n: {
      english: 'Slovenia',
      persian: 'اسلوونی',
      turkish: 'Slovenya',
      russian: 'Словения',
    },
  },
  {
    i: 77,
    n: {
      english: 'Moldova',
      persian: 'مولداوی',
      turkish: 'Moldova',
      russian: 'Молдова',
    },
  },
  {
    i: 78,
    n: {
      english: 'Lebanon',
      persian: 'لبنان',
      turkish: 'Lübnan',
      russian: 'Ливан',
    },
  },
  {
    i: 79,
    n: {
      english: 'Denmark',
      persian: 'دانمارک',
      turkish: 'Danimarka',
      russian: 'Дания',
    },
  },
  {
    i: 80,
    n: {
      english: 'USA',
      persian: 'ایالات متحده',
      turkish: 'ABD',
      russian: 'США',
    },
  },
  {
    i: 81,
    n: {
      english: 'Angola',
      persian: 'آنگولا',
      turkish: 'Angola',
      russian: 'Ангола',
    },
  },
  {
    i: 82,
    n: {
      english: 'Benin',
      persian: 'بنین',
      turkish: 'Benin',
      russian: 'Бенин',
    },
  },
  {
    i: 83,
    n: {
      english: 'Botswana',
      persian: 'بوتسوانا',
      turkish: 'Botsvana',
      russian: 'Ботсвана',
    },
  },
  {
    i: 84,
    n: {
      english: 'Burkina Faso',
      persian: 'بورکینافاسو',
      turkish: 'Burkina Faso',
      russian: 'Буркина-Фасо',
    },
  },
  {
    i: 85,
    n: {
      english: 'Cape Verde Islands',
      persian: 'جزایر کیپ ورد',
      turkish: 'Yeşil Burun Adaları',
      russian: 'Острова Кабо-Верде',
    },
  },
  {
    i: 86,
    n: {
      english: 'Comoros',
      persian: 'کومور',
      turkish: 'Komorlar',
      russian: 'Коморские острова',
    },
  },
  {
    i: 87,
    n: {
      english: 'Congo',
      persian: 'کنگو',
      turkish: 'Kongo',
      russian: 'Конго',
    },
  },
  {
    i: 88,
    n: {
      english: 'Congo DR',
      persian: 'DR کنگو',
      turkish: 'Kongo DR',
      russian: 'Конго ДР',
    },
  },
  {
    i: 89,
    n: {
      english: 'Ivory Coast',
      persian: 'ساحل عاج',
      turkish: 'Fildişi Sahili',
      russian: "Кот-д'Ивуар",
    },
  },
  {
    i: 90,
    n: {
      english: 'Egypt',
      persian: 'مصر',
      turkish: 'Mısır',
      russian: 'Египет',
    },
  },
  {
    i: 91,
    n: {
      english: 'Ethiopia',
      persian: 'اتیوپی',
      turkish: 'Etiyopya',
      russian: 'Эфиопия',
    },
  },
  {
    i: 92,
    n: {
      english: 'Gabon',
      persian: 'گابن',
      turkish: 'Gabon',
      russian: 'Габон',
    },
  },
  {
    i: 93,
    n: {
      english: 'Gambia',
      persian: 'گامبیا',
      turkish: 'Gambiya',
      russian: 'Гамбия',
    },
  },
  {
    i: 94,
    n: {
      english: 'Ghana',
      persian: 'غنا',
      turkish: 'Gana',
      russian: 'Гана',
    },
  },
  {
    i: 95,
    n: {
      english: 'Kenya',
      persian: 'کنیا',
      turkish: 'Kenya',
      russian: 'Кения',
    },
  },
  {
    i: 96,
    n: {
      english: 'Lesotho',
      persian: 'لسوتو',
      turkish: 'Lesotho',
      russian: 'Лесото',
    },
  },
  {
    i: 97,
    n: {
      english: 'Libya',
      persian: 'لیبی',
      turkish: 'Libya',
      russian: 'Ливия',
    },
  },
  {
    i: 98,
    n: {
      english: 'Madagascar',
      persian: 'ماداگاسکار',
      turkish: 'Madagaskar',
      russian: 'Мадагаскар',
    },
  },
  {
    i: 99,
    n: {
      english: 'Malawi',
      persian: 'مالاوی',
      turkish: 'Malavi',
      russian: 'Малави',
    },
  },
  {
    i: 100,
    n: {
      english: 'Mali',
      persian: 'مالی',
      turkish: 'Mali',
      russian: 'Мали',
    },
  },
  {
    i: 101,
    n: {
      english: 'Mauritania',
      persian: 'موریتانی',
      turkish: 'Moritanya',
      russian: 'Мавритания',
    },
  },
  {
    i: 102,
    n: {
      english: 'Mauritius',
      persian: 'موریس',
      turkish: 'Mauritius',
      russian: 'Маврикий',
    },
  },
  {
    i: 103,
    n: {
      english: 'Mozambique',
      persian: 'موزامبیک',
      turkish: 'Mozambik',
      russian: 'Мозамбик',
    },
  },
  {
    i: 104,
    n: {
      english: 'Namibia',
      persian: 'ناميبيا',
      turkish: 'Namibya',
      russian: 'Намибия',
    },
  },
  {
    i: 105,
    n: {
      english: 'Niger',
      persian: 'نیجر',
      turkish: 'Nijer',
      russian: 'Нигер',
    },
  },
  {
    i: 106,
    n: {
      english: 'Nigeria',
      persian: 'نیجریه',
      turkish: 'Nijerya',
      russian: 'Нигерия',
    },
  },
  {
    i: 107,
    n: {
      english: 'Rwanda',
      persian: 'رواندا',
      turkish: 'Ruanda',
      russian: 'Руанда',
    },
  },
  {
    i: 108,
    n: {
      english: 'Senegal',
      persian: 'سنگال',
      turkish: 'Senegal',
      russian: 'Сенегал',
    },
  },
  {
    i: 109,
    n: {
      english: 'Seychelles',
      persian: 'سیشل',
      turkish: 'Seyşeller',
      russian: 'Сейшельские острова',
    },
  },
  {
    i: 110,
    n: {
      english: 'Sudan',
      persian: 'سودان',
      turkish: 'Sudan',
      russian: 'Судан',
    },
  },
  {
    i: 111,
    n: {
      english: 'Swaziland',
      persian: 'سوازیلند',
      turkish: 'Svaziland',
      russian: 'Свазиленд',
    },
  },
  {
    i: 112,
    n: {
      english: 'Tanzania',
      persian: 'تانزانیا',
      turkish: 'Tanzanya',
      russian: 'Танзания',
    },
  },
  {
    i: 113,
    n: {
      english: 'Togo',
      persian: 'رفتن',
      turkish: 'Togo',
      russian: 'идти',
    },
  },
  {
    i: 114,
    n: {
      english: 'Tunisia',
      persian: 'تونس',
      turkish: 'Tunus',
      russian: 'Тунис',
    },
  },
  {
    i: 115,
    n: {
      english: 'Uganda',
      persian: 'اوگاندا',
      turkish: 'Uganda',
      russian: 'Уганда',
    },
  },
  {
    i: 116,
    n: {
      english: 'Zambia',
      persian: 'زامبیا',
      turkish: 'Zambiya',
      russian: 'Замбия',
    },
  },
  {
    i: 117,
    n: {
      english: 'Zimbabwe',
      persian: 'زیمبابوه',
      turkish: 'Zimbabve',
      russian: 'Зимбабве',
    },
  },
  {
    i: 118,
    n: {
      english: 'Bahrain',
      persian: 'بحرین',
      turkish: 'Bahreyn',
      russian: 'Бахрейн',
    },
  },
  {
    i: 119,
    n: {
      english: 'Bangladesh',
      persian: 'بنگلادش',
      turkish: 'Bangladeş',
      russian: 'Бангладеш',
    },
  },
  {
    i: 120,
    n: {
      english: 'Bhutan',
      persian: 'بوتان',
      turkish: 'Butan',
      russian: 'Бутан',
    },
  },
  {
    i: 121,
    n: {
      english: 'Brunei Darussalam',
      persian: 'برونئی دارالسلام',
      turkish: 'Brunei Sultanlığı',
      russian: 'Бруней-Даруссалам',
    },
  },
  {
    i: 122,
    n: {
      english: 'Cambodia',
      persian: 'کامبوج',
      turkish: 'Kamboçya',
      russian: 'Камбоджа',
    },
  },
  {
    i: 123,
    n: {
      english: 'Chinese Taipei',
      persian: 'چین تایپه',
      turkish: 'Çin Taipei',
      russian: 'Китайский Тайбэй',
    },
  },
  {
    i: 124,
    n: {
      english: 'Guam',
      persian: 'گوام',
      turkish: 'Guam',
      russian: 'Гуам',
    },
  },
  {
    i: 125,
    n: {
      english: 'Hong Kong',
      persian: 'هنگ کنگ',
      turkish: 'Hong Kong',
      russian: 'Гонконг',
    },
  },
  {
    i: 126,
    n: {
      english: 'Iraq',
      persian: 'عراق',
      turkish: 'Irak',
      russian: 'Ирак',
    },
  },
  {
    i: 127,
    n: {
      english: 'Jordan',
      persian: 'اردن',
      turkish: 'Ürdün',
      russian: 'Иордания',
    },
  },
  {
    i: 128,
    n: {
      english: 'Kuwait',
      persian: 'کویت',
      turkish: 'Kuveyt',
      russian: 'Кувейт',
    },
  },
  {
    i: 129,
    n: {
      english: 'Kyrgyzstan',
      persian: 'قرقیزستان',
      turkish: 'Kırgızistan',
      russian: 'Киргизия',
    },
  },
  {
    i: 130,
    n: {
      english: 'Macau',
      persian: 'ماکائو',
      turkish: 'Makao',
      russian: 'Макао',
    },
  },
  {
    i: 131,
    n: {
      english: 'Malaysia',
      persian: 'مالزی',
      turkish: 'Malezya',
      russian: 'Малайзия',
    },
  },
  {
    i: 132,
    n: {
      english: 'Maldives',
      persian: 'مالدیو',
      turkish: 'Maldivler',
      russian: 'Мальдивы',
    },
  },
  {
    i: 133,
    n: {
      english: 'Mongolia',
      persian: 'مغولستان',
      turkish: 'Moğolistan',
      russian: 'Монголия',
    },
  },
  {
    i: 134,
    n: {
      english: 'Myanmar',
      persian: 'میانمار',
      turkish: 'Myanmar',
      russian: 'Мьянма',
    },
  },
  {
    i: 135,
    n: {
      english: 'Nepal',
      persian: 'نپال',
      turkish: 'Nepal',
      russian: 'Непал',
    },
  },
  {
    i: 136,
    n: {
      english: 'Oman',
      persian: 'عمان',
      turkish: 'Umman',
      russian: 'Оман',
    },
  },
  {
    i: 137,
    n: {
      english: 'Pakistan',
      persian: 'پاکستان',
      turkish: 'Pakistan',
      russian: 'Пакистан',
    },
  },
  {
    i: 138,
    n: {
      english: 'Palestine',
      persian: 'فلسطین',
      turkish: 'Filistin',
      russian: 'Палестина',
    },
  },
  {
    i: 139,
    n: {
      english: 'Qatar',
      persian: 'قطر',
      turkish: 'Katar',
      russian: 'Катар',
    },
  },
  {
    i: 140,
    n: {
      english: 'Saudi Arabia',
      persian: 'عربستان سعودی',
      turkish: 'Suudi Arabistan',
      russian: 'Саудовская Аравия',
    },
  },
  {
    i: 141,
    n: {
      english: 'Sri Lanka',
      persian: 'سری لانکا',
      turkish: 'Sri Lanka',
      russian: 'Шри-Ланка',
    },
  },
  {
    i: 142,
    n: {
      english: 'Syria',
      persian: 'سوریه',
      turkish: 'Suriye',
      russian: 'Сирия',
    },
  },
  {
    i: 143,
    n: {
      english: 'Tajikistan',
      persian: 'تاجیکستان',
      turkish: 'Tacikistan',
      russian: 'Таджикистан',
    },
  },
  {
    i: 144,
    n: {
      english: 'Thailand',
      persian: 'تایلند',
      turkish: 'Tayland',
      russian: 'Таиланд',
    },
  },
  {
    i: 145,
    n: {
      english: 'Turkmenistan',
      persian: 'ترکمنستان',
      turkish: 'Türkmenistan',
      russian: 'Туркменистан',
    },
  },
  {
    i: 146,
    n: {
      english: 'United Arab Emirates',
      persian: 'امارات متحده عربی',
      turkish: 'Birleşik Arap Emirlikleri',
      russian: 'Объединенные Арабские Эмираты',
    },
  },
  {
    i: 147,
    n: {
      english: 'Uzbekistan',
      persian: 'ازبکستان',
      turkish: 'Özbekistan',
      russian: 'Узбекистан',
    },
  },
  {
    i: 148,
    n: {
      english: 'Yemen',
      persian: 'یمن',
      turkish: 'Yemen',
      russian: 'Йемен',
    },
  },
  {
    i: 149,
    n: {
      english: 'Albania',
      persian: 'آلبانی',
      turkish: 'Arnavutluk',
      russian: 'Албания',
    },
  },
  {
    i: 150,
    n: {
      english: 'Armenia',
      persian: 'ارمنستان',
      turkish: 'Ermenistan',
      russian: 'Армения',
    },
  },
  {
    i: 151,
    n: {
      english: 'Azerbaijan',
      persian: 'آذربایجان',
      turkish: 'Azerbeycan',
      russian: 'Азербайджан',
    },
  },
  {
    i: 152,
    n: {
      english: 'Belarus',
      persian: 'بلاروس',
      turkish: 'Belarus',
      russian: 'Беларусь',
    },
  },
  {
    i: 153,
    n: {
      english: 'Bosnia and Herzegovina',
      persian: 'بوسنی و هرزگوین',
      turkish: 'Bosna Hersek',
      russian: 'Босния и Герцеговина',
    },
  },
  {
    i: 154,
    n: {
      english: 'Faroe Islands',
      persian: 'جزایر فارو',
      turkish: 'Faroe Adaları',
      russian: 'Фарерские острова',
    },
  },
  {
    i: 155,
    n: {
      english: 'Kazakhstan',
      persian: 'قزاقستان',
      turkish: 'Kazakistan',
      russian: 'Казахстан',
    },
  },
  {
    i: 156,
    n: {
      english: 'Latvia',
      persian: 'لتونی',
      turkish: 'Letonya',
      russian: 'Латвия',
    },
  },
  {
    i: 157,
    n: {
      english: 'Luxembourg',
      persian: 'لوکزامبورگ',
      turkish: 'Lüksemburg',
      russian: 'Люксембург',
    },
  },
  {
    i: 158,
    n: {
      english: 'San Marino',
      persian: 'سان مارینو',
      turkish: 'San Marino',
      russian: 'Сан-Марино',
    },
  },
  {
    i: 159,
    n: {
      english: 'Anguilla',
      persian: 'آنگولا',
      turkish: 'Anguilla',
      russian: 'Ангилья',
    },
  },
  {
    i: 160,
    n: {
      english: 'Aruba',
      persian: 'آروبا',
      turkish: 'Aruba',
      russian: 'Аруба',
    },
  },
  {
    i: 161,
    n: {
      english: 'Bahamas',
      persian: 'باهاما',
      turkish: 'Bahamalar',
      russian: 'Багамские о-ва',
    },
  },
  {
    i: 162,
    n: {
      english: 'Barbados',
      persian: 'باربادوس',
      turkish: 'Barbados',
      russian: 'Барбадос',
    },
  },
  {
    i: 163,
    n: {
      english: 'Belize',
      persian: 'بلیز',
      turkish: 'Belize',
      russian: 'Белиз',
    },
  },
  {
    i: 164,
    n: {
      english: 'Bermuda',
      persian: 'برمودا',
      turkish: 'Bermuda',
      russian: 'Бермудские острова',
    },
  },
  {
    i: 165,
    n: {
      english: 'Cayman Islands',
      persian: 'جزایر کیمن',
      turkish: 'Cayman Adaları',
      russian: 'Каймановы острова',
    },
  },
  {
    i: 166,
    n: {
      english: 'Cuba',
      persian: 'کوبا',
      turkish: 'Küba',
      russian: 'Куба',
    },
  },
  {
    i: 167,
    n: {
      english: 'Dominican Republic',
      persian: 'جمهوری دومینیکن',
      turkish: 'Dominik Cumhuriyeti',
      russian: 'Доминиканская Респблика',
    },
  },
  {
    i: 168,
    n: {
      english: 'Grenada',
      persian: 'گرنادا',
      turkish: 'Grenada',
      russian: 'Гренада',
    },
  },
  {
    i: 169,
    n: {
      english: 'Guyana',
      persian: 'گویان',
      turkish: 'Guyana',
      russian: 'Гайана',
    },
  },
  {
    i: 170,
    n: {
      english: 'Haiti',
      persian: 'هائیتی',
      turkish: 'Haiti',
      russian: 'Гаити',
    },
  },
  {
    i: 171,
    n: {
      english: 'Jamaica',
      persian: 'جامائیکا',
      turkish: 'Jamaika',
      russian: 'Ямайка',
    },
  },
  {
    i: 172,
    n: {
      english: 'Netherlands Antilles',
      persian: 'آنتیل هلند',
      turkish: 'Hollanda Antilleri',
      russian: 'Нидерландские Антильские острова',
    },
  },
  {
    i: 173,
    n: {
      english: 'Nicaragua',
      persian: 'نیکاراگوئه',
      turkish: 'Nikaragua',
      russian: 'Никарагуа',
    },
  },
  {
    i: 174,
    n: {
      english: 'Panama',
      persian: 'پاناما',
      turkish: 'Panama',
      russian: 'Панама',
    },
  },
  {
    i: 175,
    n: {
      english: 'Puerto Rico',
      persian: 'پورتوریکو',
      turkish: 'Porto Riko',
      russian: 'Пуэрто-Рико',
    },
  },
  {
    i: 176,
    n: {
      english: 'St. Kitts and Nevis',
      persian: 'سنت کیتز و نوویس',
      turkish: 'Kitts ve Nevis',
      russian: 'Сент-Китс и Невис',
    },
  },
  {
    i: 177,
    n: {
      english: 'Suriname',
      persian: 'سورینام',
      turkish: 'Surinam',
      russian: 'Суринам',
    },
  },
  {
    i: 178,
    n: {
      english: 'Trinidad and Tobago',
      persian: 'ترینیداد و توباگو',
      turkish: 'Trinidad ve Tobago',
      russian: 'Тринидад и Тобаго',
    },
  },
  {
    i: 179,
    n: {
      english: 'Turks and Caicos Islands',
      persian: 'جزایر تورکس و کایکوس',
      turkish: 'Turks ve Caicos Adaları',
      russian: 'Острова Теркс и Кайкос',
    },
  },
  {
    i: 180,
    n: {
      english: 'US Virgin Islands',
      persian: 'جزایر ویرجین',
      turkish: 'Virgin Adaları',
      russian: 'Виргинские острова',
    },
  },
  {
    i: 181,
    n: {
      english: 'American Samoa',
      persian: 'ساموآ آمریکایی',
      turkish: 'Amerikan Samoası',
      russian: 'американское Самоа',
    },
  },
  {
    i: 182,
    n: {
      english: 'Fiji',
      persian: 'فیجی',
      turkish: 'Fiji',
      russian: 'Фиджи',
    },
  },
  {
    i: 183,
    n: {
      english: 'New Caledonia',
      persian: 'کالدونیای جدید',
      turkish: 'Yeni Kaledonya',
      russian: 'Новая Каледония',
    },
  },
  {
    i: 184,
    n: {
      english: 'Papua New Guinea',
      persian: 'پاپوا گینه نو',
      turkish: 'Papua Yeni Gine',
      russian: 'Папуа - Новая Гвинея',
    },
  },
  {
    i: 185,
    n: {
      english: 'Samoa',
      persian: 'ساموآ',
      turkish: 'Samoa',
      russian: 'Самоа',
    },
  },
  {
    i: 186,
    n: {
      english: 'Solomon Islands',
      persian: 'جزایر سلیمان',
      turkish: 'Solomon Adaları',
      russian: 'Соломоновы острова',
    },
  },
  {
    i: 187,
    n: {
      english: 'Tahiti',
      persian: 'تاهیتی',
      turkish: 'Tahiti',
      russian: 'Таити',
    },
  },
  {
    i: 188,
    n: {
      english: 'Vanuatu',
      persian: 'واناتو',
      turkish: 'Vanuatu',
      russian: 'Вануату',
    },
  },
  {
    i: 189,
    n: {
      english: 'Chile',
      persian: 'شیلی',
      turkish: 'Şili',
      russian: 'Чили',
    },
  },
  {
    i: 190,
    n: {
      english: 'Ecuador',
      persian: 'اکوادور',
      turkish: 'Ekvador',
      russian: 'Эквадор',
    },
  },
  {
    i: 192,
    n: {
      english: 'Africa',
      persian: 'آفریقا',
      turkish: 'Afrika',
      russian: 'Африка',
    },
  },
  {
    i: 194,
    n: {
      english: 'Burundi',
      persian: 'بوروندی',
      turkish: 'Burundi',
      russian: 'Бурунди',
    },
  },
  {
    i: 195,
    n: {
      english: 'Gibraltar',
      persian: 'جبل الطارق',
      turkish: 'Cebelitarık',
      russian: 'Гибралтар',
    },
  },
  {
    i: 196,
    n: {
      english: 'Antigua and Barbuda',
      persian: 'آنتیگوا و باربودا',
      turkish: 'Antigua ve Barbuda',
      russian: 'Антигуа и Барбуда',
    },
  },
  {
    i: 199,
    n: {
      english: 'Kosovo',
      persian: 'کوزوو',
      turkish: 'Kosova',
      russian: 'Косово',
    },
  },
  {
    i: 201,
    n: {
      english: 'Curacao',
      persian: 'کوراکائو',
      turkish: 'Curacao',
      russian: 'ликер кюрасо',
    },
  },
  {
    i: 202,
    n: {
      english: 'Monaco',
      persian: 'موناکو',
      turkish: 'Monako',
      russian: 'Монако',
    },
  },
  {
    i: 203,
    n: {
      english: 'Lichtenstein',
      persian: 'لیختن اشتاین',
      turkish: 'Lihtenştayn',
      russian: 'Лихтенштейн',
    },
  },
  {
    i: 204,
    n: {
      english: 'Northern Cyprus',
      persian: 'قبرس شمالی',
      turkish: 'Kuzey Kıbrıs Türk Cumhuriyeti',
      russian: 'Северный Кипр',
    },
  },
  {
    i: 206,
    n: {
      english: 'Oceania',
      persian: 'اقیانوسیه',
      turkish: 'Okyanusya',
      russian: 'Океания',
    },
  },
  {
    i: 207,
    n: {
      english: 'French Guiana',
      persian: 'گویان فرانسه',
      turkish: 'Fransız Guyanası',
      russian: 'Французская Гвиана',
    },
  },
  {
    i: 209,
    n: {
      english: 'Laos',
      persian: 'لائوس',
      turkish: 'Laos',
      russian: 'Лаос',
    },
  },
  {
    i: 210,
    n: {
      english: 'Liberia',
      persian: 'لیبریا',
      turkish: 'Liberya',
      russian: 'Либерия',
    },
  },
  {
    i: 211,
    n: {
      english: 'Afghanistan',
      persian: 'افغانستان',
      turkish: 'Afganistan',
      russian: 'Афганистан',
    },
  },
  {
    i: 214,
    n: {
      english: 'Dominica',
      persian: 'دومینیکا',
      turkish: 'Dominika',
      russian: 'Доминика',
    },
  },
  {
    i: 215,
    n: {
      english: 'Guadeloupe',
      persian: 'گوادلوپ',
      turkish: 'Guadelup',
      russian: 'Гваделупа',
    },
  },
  {
    i: 216,
    n: {
      english: 'Zanzibar',
      persian: 'زنگبار',
      turkish: 'Zanzibar',
      russian: 'Занзибар',
    },
  },
  {
    i: 217,
    n: {
      english: 'Djibouti',
      persian: 'جیبوتی',
      turkish: 'Cibuti',
      russian: 'Джибути',
    },
  },
];
