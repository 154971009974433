import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import Styled from 'styled-components/macro';

import { useMediaPredicate } from 'react-media-hook';
import { SideNavContext } from 'context/sideNavContext';
import { LoadingContext } from 'context/loadingContext';
import LogoP from 'assets/images/logo-l.svg';
import LogoFull from 'assets/images/logo.svg';
import sideNavData from 'nav.json';
import UserActions from 'components/organisms/UserActions';
import { AuthContext } from 'context/authContext';
import { SideNavbar, Nav, Ul, CloseButton, LogoHolder, Logo, SearchField, StyledField } from './SideNav.styles';
import SubMenu from './SubMenu';
import { useContextHook } from 'use-context-hook';

function Navbar() {
  const { toggleSideNav, sideNavState } = useContextHook(SideNavContext, {
    toggleSideNav: 1,
    sideNavState: 1,
  });
  const [searchText, setSearchText] = useState('');
  const { allowedPages } = useContextHook(AuthContext, {
    allowedPages: 1,
  });
  const { isLoading } = useContextHook(LoadingContext, {
    isLoading: 1,
  });
  const MaxWidth991 = useMediaPredicate('(max-width: 991px)');
  useEffect(() => {
    !sideNavState && document.body.classList.remove('nav-active');
  }, [sideNavState]);
  const sideBarItems = useMemo(
    () =>
      sideNavData
        .filter(({ file, live }) =>
          !searchText.trim()
            ? allowedPages.includes(file) &&
              (window?.process?.env?.REACT_APP_MAIN_URL === 'https://backoffice.geniusfeeds.work' ? live : true)
            : allowedPages.includes(file) &&
              file.includes(searchText.trim()) &&
              (window?.process?.env?.REACT_APP_MAIN_URL === 'https://backoffice.geniusfeeds.work' ? live : true),
        )
        .map((item, index) => <SubMenu item={item} key={index} />),
    [searchText, isLoading, sideNavData, allowedPages],
  );
  return (
    <>
      <SideNavbar css={isLoading && 'background:var(--dark);'} $loading={isLoading}>
        <LogoHolder>
          <Logo to="/">
            <img src={LogoP} alt="genius" className="logo-small" />
            <img src={LogoFull} alt="genius" className="logo-full" />
          </Logo>
        </LogoHolder>
        {MaxWidth991 && (
          <CloseButton onClick={toggleSideNav}>
            <i className="material-icons-outlined">close</i>
          </CloseButton>
        )}
        <SearchField>
          <StyledField
            type="search"
            placeholder="Search..."
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            prefix={<i className="material-icons-outlined">search</i>}
          />
        </SearchField>

        <Nav>
          <Ul>{sideBarItems}</Ul>
        </Nav>
        <UserActions
          toggleSideNav={toggleSideNav}
          profile={sideBarItems.filter(item => item.props.item.name === 'Profile')}
        />
      </SideNavbar>
    </>
  );
}
export default Navbar;
