import styled, { css } from 'styled-components/macro';

const getColor = iconName => {
  switch (iconName) {
    case 'settings':
      return 'light-blue';
    case 'check_circle':
      return 'success';
    case 'cancel':
      return 'danger';
    case 'error':
    case 'do_not_disturb':
    case 'watch_later':
      return 'warning';
    default:
      return 'black';
  }
};
export const I = styled.span`
  font-size: ${props => (props.size ? props.size : '20px')} !important;
  color: ${({ iconName }) => `var(--${getColor(iconName)})`};
  line-height: 1;
  ${({ blink }) =>
    blink &&
    css`
      animation: blink 1s infinite;
    `}
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  cursor: pointer;
`;

export const IconHolder = styled.div``;
