/* eslint-disable no-unreachable */
import React, { useState, useEffect, useCallback } from 'react';
import { clearCookie, getCookie, setCookie } from 'helpers/common';
import Toast from 'components/molecules/Toast';
import backendService from 'services/backendService';
import { useCancellablePromise } from 'helpers/promiseHandler';
import { createContextHook } from 'use-context-hook';

const context = {};

export const AuthContext = createContextHook(context);

export const AuthContextProvider = props => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!getCookie('admin_token'));
  const [gmt, setGMT] = useState({ value: '0', label: 'GMT 0' });
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({});
  const [loading_user, setLoadingUser] = useState(false);
  const [fetch_user, setFetchUser] = useState(false);
  const { cancellablePromise } = useCancellablePromise();
  const [reFetch, setRefetch] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [allowedPages, setAllowedPages] = useState(JSON.parse(getCookie('allowed_pages')) || []);

  const onLogout = useCallback(async () => {
    if (isLoggedIn) {
      try {
        if (!getCookie('admin_token')) {
          return;
        }
        setIsLoggedIn(false);
        const res = await backendService.removeAdminJwt();
        if (res) {
          clearCookie('admin_token');
          clearCookie('allowed_pages');
        }
      } catch (ex) {
        clearCookie('admin_token');
        clearCookie('allowed_pages');
        Toast({ type: 'error', message: ex?.message });
      }
    } else {
      clearCookie('admin_token');
      clearCookie('allowed_pages');
    }
  }, [isLoggedIn]);

  const getPermissions = useCallback(() => {
    if (isLoggedIn) {
      if (!getCookie('admin_token')) {
        return;
      }
      setLoadingUser(true);
      cancellablePromise(backendService.getCurrentAdmin(isLoggedIn))
        .then(res => {
          const new_perms = res.permissions.filter(p => p.includes('.nav')).map(p => p.split('.')[0]);
          if (!new_perms.length) {
            new_perms.push('no-permissions');
          }
          setAllowedPages(new_perms);
          setCookie('allowed_pages', JSON.stringify(new_perms));
          setLoadingUser(false);
          setUser(res);
        })
        .catch(err => {
          setAllowedPages(['no-permissions']);
          setCookie('allowed_pages', JSON.stringify(['no-permissions']));
          setLoadingUser(false);
          Toast({
            type: 'error',
            message: err.message,
          });
        });
    }
  }, [cancellablePromise, isLoggedIn]);
  /**
   * @description - This function is used to fetch the user details from the server
   */
  useEffect(() => {
    if (isLoggedIn) {
      getPermissions();
    }
  }, [isLoggedIn, fetch_user]);

  const onLogin = async ({ email, password, token }) => {
    setLoadingUser(true);
    try {
      if (token) {
        const res = await backendService.login({
          email,
          password,
          token,
        });
        if (!res?.token) {
          throw new Error(res?.message);
        }

        setCookie('admin_token', res.token);
        setIsLoggedIn(true);
      } else {
        const res = await backendService.registerOtp({
          email,
          password,
        });

        if (!res?.token) {
          throw new Error(res?.message);
        }

        setUser(res.token);
        setShowTokenModal(true);
      }
      setLoadingUser(false);
    } catch ({ message }) {
      setIsLoggedIn(false);
      setLoadingUser(false);
      Toast({ type: 'error', message });
    }
  };

  /**
   * @description - If someone tries to temper with the cookies we take the appropriate action
   */
  useEffect(() => {
    const gtm = localStorage.getItem('gmt');

    if (gtm) {
      setGMT({ value: gtm, label: `GMT ${gtm}` });
    } else {
      setGMT({ value: '0', label: 'GMT 0' });
      localStorage.setItem('gmt', '0');
    }
    // function listenCookieChange(callback, interval) {
    //   let old_token = getCookie('admin_token');
    //   let old_allowed = getCookie('allowed_pages');
    //   setInterval(() => {
    //     const new_token = getCookie('admin_token');
    //     const new_allowed = getCookie('allowed_pages');
    //     if (new_token !== old_token) {
    //       try {
    //         callback(new_token, 'admin_token');
    //       } finally {
    //         old_token = new_token;
    //       }
    //     }
    //     if (new_allowed !== old_allowed) {
    //       try {
    //         callback(new_allowed, 'allowed_pages');
    //       } finally {
    //         old_allowed = new_allowed;
    //       }
    //     }
    //   }, interval);
    // }
    // listenCookieChange((value, cookie) => {
    //   if (cookie === 'admin_token') {
    //     if (!value) {
    //       onLogout();
    //     }
    //   }
    // }, 1000);
  }, [onLogout]);

  const hasPermission = perm => user?.permissions?.includes(perm);

  return (
    <AuthContext.Provider
      value={{
        setIsLoggedIn,
        onLogout,
        onLogin,
        gmt,
        setGMT: _gmt => {
          setGMT(_gmt);
          localStorage.setItem('gmt', _gmt.value);
        },
        refetch: () => setRefetch(_ => !_),
        fetchUser: () => setFetchUser(() => !fetch_user),
        setShowTokenModal,
        setLoading,
        hasPermission,
        allowedPages,
        showTokenModal,
        loading,
        isLoggedIn,
        fetch: reFetch,
        user,
        loading_user,
      }}>
      {props.children}
    </AuthContext.Provider>
  );
};
