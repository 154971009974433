/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable no-plusplus */
import Icon from 'components/atoms/Icon';
import Tooltip from 'components/atoms/Tooltip';
import { format } from 'date-fns/esm';
import Compress from 'react-image-file-resizer';
import countries from './markets/countries';

export const setCookie = (name, value) => {
  localStorage.setItem(name, value);
  return true;
};

export const getCookie = name => {
  const value = localStorage.getItem(name);
  return value;
};

export const clearCookie = name => {
  localStorage.removeItem(name);
  return true;
};

export const compressImage = file =>
  new Promise(resolve => {
    Compress.imageFileResizer(
      file,
      Infinity,
      Infinity,
      'JPEG',
      70,
      0,
      uri => {
        resolve(uri);
      },
      'base64',
    );
  });

export const capitalize = (str = '') => {
  const arr = str.toLowerCase().split(' ');

  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(' ');
  return str2;
};

export const getStatusIconClass = (status = '') => {
  switch (status.trim().toLowerCase()) {
    case 'pending' || 'processing':
      return 'icon-clock';
    case 'approved':
      return 'icon-check-circle';
    case 'rejected':
      return 'icon-error-circle';
    case 'cancelled':
      return 'icon-times-circle';
    default:
      return 'icon-warning';
  }
};

export const getDateObject = (e = '') => {
  const date = new Date(e);
  date.toLocaleTimeString('en-US', { timeZone: 'Canada/Eastern' });
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(date?.getTime())) {
    const x = new Date();
    x.toLocaleTimeString('en-US', { timeZone: 'Canada/Eastern' });
    return x;
  }
  return date;
};

export const convertToCurrencyFormat = (amount = '0') =>
  `$ ${Number(amount)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const shortenString = (str = '', len = 10) => {
  if (str?.length > len) {
    return `${str.substring(0, len)}...`;
  }
  return str;
};

export const convertReadable = (amount = 0) =>
  `${
    Math.abs(amount) > 999
      ? `${Math.sign(amount) * (Math.abs(amount) / 1000).toFixed(1)}K`
      : Math.sign(amount) * Math.abs(amount)
  }`;

export const SPORTS_ID_NAME = {
  1: 'SOCCER',
  7: 'BASEBALL',
  12: 'RUGBY_UNION',
  13: 'TENNIS',
  14: 'AMERICAN_FOOTBALL',
  17: 'ICE_HOCKEY',
  18: 'BASKETBALL',
  20: 'TABLE_TENNIS',
  24: 'CRICKET',
  31: 'RUGBY_LEAUGE',
  32: 'E_SPORTS',
  91: 'VOLLEYBALL',
  777: 'Genius Feed',
};
export const SPORTS_ID_NAME_RACING = {
  344: 'DOG_RACING',
  34: 'HORSE_RACING',
};
export const bm_names = {
  1: 'Bet365',
  3: 'FonBet',
  11: 'B WIN',
  5: '1xBet',
  777: 'Genius Feed',
};

export const SPORTS_NAME_ID = {
  SOCCER: 1, // up
  BASKETBALL: 18, // down
  BASEBALL: 7, //
  E_SPORTS: 32, // down
  ICE_HOCKEY: 17, // down
  CRICKET: 24, //no time
  VOLLEYBALL: 91, // no time
  TENNIS: 13, // no time
  TABLE_TENNIS: 20, // no time
  RUGBY_LEAUGE: 31,
  RUGBY_UNION: 12,
  AMERICAN_FOOTBALL: 14,
};

export const initialEvents = (bm = '1') => {
  if (bm == 777) {
    const sports = [
      {
        777: 'Genius Feed',
      },
    ];
    return sports
      .map(sport => ({
        sp: Object.keys(sport)[0],
        bm,
        l: [],
      }))
      .sort(({ sp }, { sp: _sp }) => sp - _sp);
  } else {
    const sports = [
      {
        1: 'SOCCER',
      },
      {
        18: 'BASKETBALL',
      },
      {
        7: 'BASEBALL',
      },
      {
        32: 'E_SPORTS',
      },
      {
        17: 'ICE_HOCKEY',
      },
      {
        91: 'VOLLEYBALL',
      },
      {
        13: 'TENNIS',
      },
      {
        20: 'TABLE_TENNIS',
      },
      {
        24: 'CRICKET',
      },
      {
        31: 'RUGBY_LEAUGE',
      },
      {
        12: 'RUGBY_UNION',
      },
      {
        14: 'AMERICAN_FOOTBALL',
      },
    ];
    return sports
      .map(sport => ({
        sp: Object.keys(sport)[0],
        bm,
        l: [],
      }))
      .sort(({ sp }, { sp: _sp }) => sp - _sp);
  }
};
export const initialEventsRacing = (bm = 'dog') => {
  const sports_1 = [
    {
      344: 'DOG_RACING',
    },
  ];
  const sports_2 = [
    {
      34: 'HORSE_RACING',
    },
  ];
  // eslint-disable-next-line eqeqeq
  return bm == 'dog'
    ? sports_1
        .map(sport => ({
          sp: Object.keys(sport)[0],
          bm: 344,
          l: [],
        }))
        .sort(({ sp }, { sp: _sp }) => sp - _sp)
    : sports_2
        .map(sport => ({
          sp: Object.keys(sport)[0],
          bm: 34,
          l: [],
        }))
        .sort(({ sp }, { sp: _sp }) => sp - _sp);
};

const getStatus = _ => (
  <Tooltip placement="topLeft" title={_ ? 'true' : 'false'} arrowPointAtCenter>
    {!_ ? (
      <Icon showTooltip toolTipContent="FALSE" iconName="cancel" />
    ) : (
      <Icon showTooltip toolTipContent="TRUE" iconName="check_circle" />
    )}
  </Tooltip>
);

export const mapDictData = (sport, data, user) => {
  const isClient = user.role_type[0] === 'CLIENTS' && !user.role_type.find(_ => _.includes('SUPER_ADMIN'));
  const getCountry = c => countries.find(({ i }) => Number(i) === Number(c))?.n?.english;
  switch (sport) {
    case 'sports':
      return isClient
        ? data.map(({ ids, n }) => [ids.I, n.english])
        : data.map(({ ids, n, status }) => [ids.I, n.english, getStatus(status)]);
    case 'countries':
      return isClient
        ? data.map(({ i, n }) => [i, n.english, n.persian, n.turkish, n.russian])
        : data.map(({ i, n, cc }) => [i, n.english, n.persian, n.turkish, n.russian, cc]);
    case 'removedevents':
      return data.map(({ i, l, s, t1i, t2i, rid }) => [i, l, SPORTS_ID_NAME[Number(s)] ?? s, t1i, t2i, rid]);
    case 'bookmakers':
      return isClient
        ? data.map(({ ids, n }) => [ids.I, n])
        : data.map(({ ids, n, status }) => [ids.I, ids.pv1, n, getStatus(status)]);
    case 'leagues':
      return data.map(({ i, n, s, c }) => [i, n, SPORTS_ID_NAME[Number(s)] ?? s, getCountry(c)]);
    case 'liveperiods':
      return data.map(({ c, e, s }) => [c, e, SPORTS_ID_NAME[Number(s)] ?? s]);
    case 'prelivemarkets':
      return data.map(({ i, n, s, he }) => [i, n, SPORTS_ID_NAME[Number(s)] ?? s, getStatus(!!Number(he))]);
    case 'teams':
      return data.map(({ i, c, n, nl, s }) => [i, getCountry(c), n, nl, SPORTS_ID_NAME[Number(s)] ?? s]);
    case 'livestates':
      return data.map(({ c, e, s }) => [c, e, SPORTS_ID_NAME[Number(s)] ?? s]);
    case 'livestats':
      return data.map(({ c, e, s }) => [c, e, SPORTS_ID_NAME[Number(s)] ?? s]);
    default:
      return [];
  }
};
export const mapDictDataColumns = (sport, user) => {
  const isClient = user.role_type[0] === 'CLIENTS' && !user.role_type.find(_ => _.includes('SUPER_ADMIN'));
  switch (sport) {
    case 'sports':
      return isClient ? ['ID', 'Name'] : ['ID', 'Name', 'Status'];
    case 'removedevents':
      return ['ID', 'league id', 'Sport', 'T1', 'T2', 'RID'];
    case 'bookmakers':
      return isClient ? ['Our ID', 'Name'] : ['Our ID', 'PV1 ID', 'Name', 'Status'];
    case 'leagues':
      return ['ID', 'league name', 'Sport', 'Country'];
    case 'liveperiods':
      return ['Code', 'Explanation', 'Sport'];
    case 'prelivemarkets':
      return ['ID', 'Name', 'Sport', 'HA'];
    case 'teams':
      return ['ID', 'Country', 'Name', 'Long Name', 'Sport'];
    case 'livestates':
      return ['Code', 'Explanation', 'Sport'];
    case 'countries':
      return isClient
        ? ['ID', 'English Name', 'Persian Name', 'Turkish Name', 'Russian Name']
        : ['ID', 'English Name', 'Persian Name', 'Turkish Name', 'Russian Name', 'CC'];
    case 'livestats':
      return ['Code', 'Explanation', 'Sport'];
    default:
      return ['ID', 'Name', 'Explanation'];
  }
};
export const convertToGMT = timeInMilliseconds => {
  const gmtValue = localStorage.getItem('gmt');
  const date = new Date(timeInMilliseconds * 1000);
  const gmt = date.getTimezoneOffset() / 60 + Number(gmtValue);
  return format(new Date(date.getTime() + gmt * 60 * 60 * 1000), 'MM-dd-yyyy HH:mm:ss');
};
