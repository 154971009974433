import styled, { css } from 'styled-components/macro';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Day = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #ccc;
  }
  ${({ selected }) =>
    selected &&
    css`
      background-color: #ccc;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #eee;
      cursor: not-allowed;
    `}
    ${({ error }) =>
    error &&
    css`
      border: 1px solid #f44;
    `}
`;
