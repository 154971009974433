import { initialEvents, initialEventsRacing, setCookie, initialEvents777 } from 'helpers/common';
import { useEffect, useState } from 'react';
import { Fetch } from '../helpers/fetchWrapper';
import { useCancellablePromise } from '../helpers/promiseHandler';

const STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
};
const backendService = {
  _url: process.env.REACT_APP_API_URL,
  GetTickets(searchQuery) {
    const [tickets, setTickets] = useState({
      tickets: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getTickets(searchQuery))
        .then(res => {
          setTickets(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.customerId,
      searchQuery?.startDate,
      searchQuery?.endDate,
    ]);
    return {
      tickets_loading: status === STATUS.LOADING,
      tickets_error: status === STATUS.ERROR ? status : '',
      tickets_data: tickets,
    };
  },
  GetMarketData(searchQuery, refetch) {
    const [markets, setMarkets] = useState({
      markets: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getMarketData(searchQuery))
        .then(res => {
          setMarkets(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery, refetch]);
    return {
      markets_loading: status === STATUS.LOADING,
      markets_error: status === STATUS.ERROR ? status : '',
      markets_data: markets,
    };
  },
  GetMarketDataAll(sport) {
    const [markets, setMarkets] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getMarketsAll({ sport }))
        .then(res => {
          setMarkets(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [sport]);
    return {
      markets_loading: status === STATUS.LOADING,
      markets_error: status === STATUS.ERROR ? status : '',
      markets_data: markets,
    };
  },
  GetAllEvents(searchQuery, refetch) {
    const [events, setEvents] = useState({
      events: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAllEvents(searchQuery))
        .then(res => {
          setEvents(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      refetch,
      searchQuery.page,
      searchQuery.pageSize,
      searchQuery.filterSport,
      searchQuery.filterBm,
      searchQuery.filterMid,
    ]);
    return {
      events_loading: status === STATUS.LOADING,
      events_error: status === STATUS.ERROR ? status : '',
      events_data: events,
    };
  },
  GetSportsData(bm, refetch, version = 1, for_racing = false, animal) {
    const [data, setData] = useState(for_racing ? initialEventsRacing(animal) : initialEvents(bm));
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getSportsData(bm, version))
        .then(res => {
          setData(() =>
            JSON.parse(res?.data)?.length
              ? JSON.parse(res?.data)
              : for_racing
              ? initialEventsRacing(animal)
              : initialEvents(bm),
          );
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [bm, refetch, version]);
    return {
      live_data_loading: status === STATUS.LOADING,
      live_data_error: status === STATUS.ERROR ? status : '',
      live_data_data: data,
    };
  },
  GetSportsDataCID(id, refetch) {
    const [cid, setCID] = useState({});
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getSportsDataCID(id))
        .then(res => {
          setCID(() => JSON.parse(res?.data));
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [id, refetch]);
    return {
      cid_loading: status === STATUS.LOADING,
      cid_error: status === STATUS.ERROR ? status : '',
      cid_data: cid,
    };
  },
  GetDict(searchQuery, refetch) {
    const [dict, setDict] = useState({
      dict: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getDict(searchQuery))
        .then(res => {
          setDict(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery.dict,
      searchQuery?.filterStatus,
      searchQuery?.filterSport,
      refetch,
    ]);
    return {
      dict_loading: status === STATUS.LOADING,
      dict_error: status === STATUS.ERROR ? status : '',
      dict_data: dict,
    };
  },
  GetAdmins(searchQuery, refetch) {
    const [admins, setAdmins] = useState({
      admins: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAdmins(searchQuery))
        .then(res => {
          setAdmins(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterRoles,
      searchQuery?.startDate,
      searchQuery?.endDate,
      refetch,
    ]);
    return {
      admins_loading: status === STATUS.LOADING,
      admins_error: status === STATUS.ERROR ? status : '',
      admins_data: admins,
    };
  },
  GetRoles(searchQuery, refetch) {
    const [roles, setRoles] = useState({
      totalItems: 0,
      roles: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getRoles(searchQuery))
        .then(res => {
          setRoles(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterRoles,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      refetch,
    ]);
    return {
      roles_loading: status === STATUS.LOADING,
      roles_error: status === STATUS.ERROR ? status : '',
      roles_data: roles,
    };
  },
  GetPermissions(searchQuery, refetch) {
    const [permissions, setPermissions] = useState({
      totalItems: 0,
      permissions: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getPermissions(searchQuery))
        .then(res => {
          setPermissions(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.searchText,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterText,
      searchQuery?.startDate,
      searchQuery?.endDate,
      searchQuery?.getAll,
      searchQuery?.parentOnly,
      searchQuery?.filterPermission,
      refetch,
    ]);
    return {
      permissions_loading: status === STATUS.LOADING,
      permissions_error: status === STATUS.ERROR ? status : '',
      permissions_data: permissions,
    };
  },
  GetPreLiveData(searchQuery) {
    const [preLiveData, setPreLiveData] = useState({
      totalItems: 0,
      preLiveData: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getPreLiveData(searchQuery))
        .then(res => {
          setPreLiveData(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.filterSport,
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.searchText,
      searchQuery?.filterCountry,
      searchQuery?.filterLeagues,
      searchQuery?.filterTime,
    ]);
    return {
      preLiveData_loading: status === STATUS.LOADING,
      preLiveData_error: status === STATUS.ERROR ? status : '',
      preLiveData_data: preLiveData,
    };
  },
  GetCountriesLeagues(sp, country) {
    const [options_data, setOptions] = useState({
      leagues: [],
      countries: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getPreLiveData({ optionsOnly: 'true', sp }))
        .then(res => {
          setOptions(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [sp]);
    useEffect(() => {
      if (country) {
        setOptions(() => ({
          leagues: options_data.leagues.filter(l => l.c === country),
          countries: options_data.countries,
        }));
      } else {
        setOptions(() => ({
          leagues: options_data.leagues,
          countries: options_data.countries,
        }));
      }
    }, [country]);

    return {
      options_loading: status === STATUS.LOADING,
      options_error: status === STATUS.ERROR ? status : '',
      options_data,
    };
  },
  GetAdminSessions(searchQuery) {
    const [admin_sessions, setAdminSessions] = useState({
      admin_sessions: [],
      totalItems: 0,
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAdminSessions(searchQuery))
        .then(res => {
          setAdminSessions(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery?.page, searchQuery?.pageSize, searchQuery?.startDate, searchQuery?.endDate]);
    return {
      admin_sessions_loading: status === STATUS.LOADING,
      admin_sessions_error: status === STATUS.ERROR ? status : '',
      admin_sessions_data: admin_sessions,
    };
  },
  GetShifts(searchQuery, refetch) {
    const [shifts, setShifts] = useState({
      totalItems: 0,
      shifts: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getShifts(searchQuery))
        .then(res => {
          setShifts(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [searchQuery?.searchText, searchQuery?.page, searchQuery?.pageSize, refetch]);
    return {
      shifts_loading: status === STATUS.LOADING,
      shifts_error: status === STATUS.ERROR ? status : '',
      shifts_data: shifts,
    };
  },
  GetAdminOptions(role = '') {
    const [options, setAdmins] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getAdminOptions(role))
        .then(res => {
          setAdmins(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [role]);
    return {
      admins_loading: status === STATUS.LOADING,
      admins_error: status === STATUS.ERROR ? status : '',
      admins_data: options,
    };
  },
  GetLOGS(searchQuery) {
    const [logs, setLogs] = useState({
      totalItems: 0,
      logs: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getLOGS(searchQuery))
        .then(res => {
          setLogs(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => {
          setStatus(STATUS.ERROR);
        });
    }, [
      searchQuery.page,
      searchQuery.pageSize,
      searchQuery.filterRoles,
      searchQuery.filterUsers,
      searchQuery.startDate,
      searchQuery.endDate,
    ]);
    return {
      logs_loading: status === STATUS.LOADING,
      logs_error: status === STATUS.ERROR ? status : '',
      logs_data: logs,
    };
  },
  GetServices(can) {
    const [services, setServices] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      if (can) {
        setStatus(STATUS.LOADING);
        cancellablePromise(this.getServices())
          .then(res => {
            setServices(() => res);
            setStatus(STATUS.SUCCESS);
          })
          .catch(() => setStatus(STATUS.ERROR));
      }
    }, [can]);
    return {
      services_loading: status === STATUS.LOADING,
      services_error: status === STATUS.ERROR ? status : '',
      services_data: services,
    };
  },
  GetLeaguesMappings(searchQuery) {
    const [leagues, setLeagues] = useState({
      totalItems: 0,
      leagues: [],
    });
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(this.getLeaguesMappings(searchQuery))
        .then(res => {
          setLeagues(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [
      searchQuery?.page,
      searchQuery?.pageSize,
      searchQuery?.filterSport,
      searchQuery?.fetch,
      searchQuery?.isMapped,
      searchQuery?.mapAble,
    ]);
    return {
      leagues_loading: status === STATUS.LOADING,
      leagues_error: status === STATUS.ERROR ? status : '',
      leagues_data: leagues,
    };
  },
  GetLeagueOptions({ sp, cc, fetch } = {}) {
    const [options, setOptions] = useState([]);
    const { cancellablePromise } = useCancellablePromise();
    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);
      cancellablePromise(
        this.getLeagueOptions({
          sp,
          cc,
        }),
      )
        .then(res => {
          setOptions(() => res);
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [sp, cc, fetch]);
    return {
      options_loading: status === STATUS.LOADING,
      options_error: status === STATUS.ERROR ? status : '',
      options_data: options,
    };
  },
  async getSportsData(id, version) {
    let res = await Fetch.get(`${this._url}/live-data/${id}?version=${version}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getSportsDataCID(cid) {
    let res = await Fetch.get(`${this._url}/live-data-cid/${cid}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getMarketData({
    page = 1,
    pageSize = 10,
    filterSport = '',
    filterRisk = '',
    filterHe = '',
    filterB3i = '',
    filterBri = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/markets?page=${page}&pageSize=${pageSize}&filterSport=${filterSport}&filterRisk=${filterRisk}&filterHe=${filterHe}&filterB3i=${filterB3i}&filterBri=${filterBri}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        markets: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getDict({ filterStatus = '', filterSport = '', searchText = '', page = 1, pageSize = 10, dict = '' }) {
    let res = await Fetch.get(
      `${this._url}/dictionary/${dict}?filterSport=${filterSport}&filterStatus=${filterStatus}&searchText=${searchText}&page=${page}&pageSize=${pageSize}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        dict: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAdmins({ page = 1, pageSize = 10, searchText, filterRoles, startDate, endDate, getAll = false }) {
    let res = await Fetch.get(
      `${this._url}/search-admins?itemsPerPage=${pageSize}&page=${page}&searchText=${searchText}&filterRoles=${filterRoles}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        admins: res.data.items,
        totalItems: res.data.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async login({ email = '', password = '', token = '' }) {
    let res = await Fetch.post(`${this._url}/login`, { email, password, token });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      setCookie('admin_token', res.token);
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async registerOtp({ email = '', password = '' }) {
    let res = await Fetch.post(`${this._url}/register-auth`, {
      email,
      password,
    });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteAdmin(id) {
    let res = await Fetch.delete(`${this._url}/admin/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async reset_auth({ generateNew, id }) {
    let res = await Fetch.post(`${this._url}/reset-auth`, { id, generateNew });
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async addAdmin(values) {
    let res = await Fetch.post(`${this._url}/admin`, values);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateAdmin(id, values) {
    let res = await Fetch.patch(`${this._url}/admin/${id}`, values);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async removeAdminJwt() {
    let res = await Fetch.delete(`${this._url}/logout`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getCurrentAdmin(isLoggedIn) {
    if (!isLoggedIn) return {};
    let res = await Fetch.get(`${this._url}/get-current-status`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getRoles({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterRoles = '',
    startDate = '',
    endDate = '',
    getAll = false,
  }) {
    let res = await Fetch.get(
      `${this._url}/role?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterRoles=${filterRoles}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        roles: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getPermissions({
    page = 1,
    pageSize = 10,
    searchText = '',
    startDate = '',
    endDate = '',
    getAll = false,
    parentOnly = false,
    filterPermission = '',
  }) {
    let res = await Fetch.get(
      `${this._url}/permission?page=${page}&perPage=${pageSize}&searchText=${searchText}&filterText=${filterPermission}&startDate=${startDate}&endDate=${endDate}&getAll=${getAll}&parentOnly=${parentOnly}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        permissions: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getPermissionsOptions({ parentOnly = false }) {
    let res = await Fetch.get(`${this._url}/permission?parentOnly=${parentOnly}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        permissionStatus: res.items.map(({ can }) => ({ label: can.split('.')[0], value: can.split('.')[0] })),
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createRole(payload) {
    let res = await Fetch.post(`${this._url}/role`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateRole(id, payload) {
    let res = await Fetch.put(`${this._url}/role/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteRole(id) {
    let res = await Fetch.delete(`${this._url}/role/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createPermission(payload) {
    let res = await Fetch.post(`${this._url}/permission`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updatePermission(id, payload) {
    let res = await Fetch.put(`${this._url}/permission/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deletePermission(id) {
    let res = await Fetch.delete(`${this._url}/permission/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async restorePermissions(payload) {
    let res = await Fetch.post(`${this._url}/restore-permissions`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async rolesFiltersOptions({ getAll = true }) {
    let res = await Fetch.get(`${this._url}/role?getAll=${getAll}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        options: res.items.map(({ type, _id }) => ({ label: type, value: _id })),
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getTickets() {
    // let res = await Fetch.get(`${this._url}/tickets`);
    // if (res.status >= 200 && res.status < 300) {
    //   res = await res.json();
    //   return {
    //     roles: res.items,
    //     totalItems: res.totalItems,
    //   };
    // }
    // const { message } = await res.json();
    // throw new Error(message ?? 'Something went wrong');
    return {
      tickets: [],
      totalItems: 0,
    };
  },
  async changeMID(payload) {
    let res = await Fetch.put(`${this._url}/change-mid`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getPreLiveData({
    page = 1,
    pageSize = 10,
    searchText = '',
    filterCountry = '',
    filterLeagues = '',
    filterSport = 1,
    optionsOnly = 'false',
    filterTime = '',
    gmt,
    forced = false,
    t1 = false,
  }) {
    let res = await Fetch.get(
      `${this._url}/pre-live/${filterSport}?t1=${t1}&force=${forced}&page=${page}&perPage=${pageSize}&searchText=${searchText}&filterCountry=${filterCountry}&filterLeagues=${filterLeagues}&optionsOnly=${optionsOnly}&filterTime=${filterTime}&gmt=${gmt}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      if (optionsOnly === 'true') {
        const countries = [
          { label: 'All', value: '' },
          ...res.countries.map(x => ({ value: x.i, label: x.n.english })),
        ];
        const leagues = [{ label: 'All', value: '' }, ...res.leagues.map(x => ({ value: x.i, label: x.n, c: x.c }))];
        return {
          countries,
          leagues,
        };
      }
      return {
        preLiveData: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getPreLiveDataMarkets(id) {
    let res = await Fetch.get(`${this._url}/pre-live-m/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();

      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAdminSessions(id) {
    let res = await Fetch.get(`${this._url}/sessions/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        admin_sessions: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getShifts({ page = 1, pageSize = 10, searchText = '' }) {
    let res = await Fetch.get(`${this._url}/shift?page=${page}&perPage=${pageSize}&searchText=${searchText}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        shifts: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getShift(id) {
    let res = await Fetch.get(`${this._url}/shift/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async createShift(payload) {
    let res = await Fetch.post(`${this._url}/shift`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateShift(id, payload) {
    let res = await Fetch.put(`${this._url}/shift/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async deleteShift(id) {
    let res = await Fetch.delete(`${this._url}/shift/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAdminOptions(role = '') {
    let res = await Fetch.get(`${this._url}/admin-options?role=${role}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async logsFiltersOptions() {
    let res = await Fetch.get(`${this._url}/admin-options`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return { options: res };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getLOGS({ page = 1, pageSize = 10, startDate = '', endDate = '', filterUsers = '', filterRoles = '' }) {
    let res = await Fetch.get(
      `${this._url}/logs?page=${page}&perPage=${pageSize}&startDate=${startDate}&endDate=${endDate}&filterUsers=${filterUsers}&filterRoles=${filterRoles}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      console.log({ res });
      return {
        logs: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getServices() {
    let res = await Fetch.get(`${this._url}/services`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getLeaguesMappings({ page = 1, pageSize = 10, filterSport = '1', isMapped = '', mapAble = '' }) {
    let res = await Fetch.get(
      `${this._url}/leagues-mappings?page=${page}&perPage=${pageSize}&filterSport=${filterSport}&isMapped=${isMapped}&mapAble=${mapAble}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        leagues: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateLeaguesMapping(payload) {
    let res = await Fetch.put(`${this._url}/leagues-mappings`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getLeagueOptions({ sp = '', cc = '' }) {
    let res = await Fetch.get(`${this._url}/mappings-data?cc=${cc}&s=${sp}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async updateMarkets(id, payload) {
    let res = await Fetch.put(`${this._url}/update-market/${id}`, payload);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getMarketsAll({ sport = '1' }) {
    let res = await Fetch.get(`${this._url}/markets-all?sport=${sport}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async fetchDetailsMid(id) {
    let res = await Fetch.get(`${this._url}/fetch-details-mid/${id}`);
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return res;
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
  async getAllEvents({ page, pageSize, filterSport, filterBm, filterMid }) {
    let res = await Fetch.get(
      `${this._url}/all-events?page=${page}&perPage=${pageSize}&filterSport=${filterSport}&filterBm=${filterBm}&filterMid=${filterMid}`,
    );
    if (res.status >= 200 && res.status < 300) {
      res = await res.json();
      return {
        events: res.items,
        totalItems: res.totalItems,
      };
    }
    const { message } = await res.json();
    throw new Error(message ?? 'Something went wrong');
  },
};
export default backendService;
