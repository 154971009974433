const defaultPermissions = require('./defaultPermission.json');

const ob = [
  'system.views.nav',
  'removedevents.nav',
  'bookmakers.nav',
  'leagues.nav',
  'liveperiods.nav',
  'prelivemarkets.nav',
  'teams.nav',
  'livestates.nav',
  'countries.nav',
  'sports.nav',
  'livestats.nav',
];

export default function defaultRoles() {
  const ROLES = [
    {
      name: 'VISITOR',
      permissions: defaultPermissions
        .filter(({ can }) => can.includes('pre-live') || (can.includes('live-data') && !can.includes('live-data.mid')))
        .map(({ can }) => can),
    },
    {
      name: 'CLIENTS',
      permissions: defaultPermissions
        .filter(
          ({ can }) =>
            can.includes('pre-live') ||
            (can.includes('live-data') && !can.includes('live-data.mid')) ||
            ob.includes(can),
        )
        .map(({ can }) => can),
    },
    {
      name: 'BACK_OFFICE',
      permissions: defaultPermissions
        .filter(
          ({ can }) =>
            !can.includes('admins') &&
            !can.includes('logs') &&
            !can.includes('permissions') &&
            !can.includes('roles') &&
            !can.includes('markets.edit-force'),
        )
        .map(({ can }) => can),
    },
  ];

  return [
    {
      id: 1,
      type: 'SUPER_ADMIN',
      permissions: defaultPermissions,
    },
    {
      id: 2,
      type: 'DEVELOPER_SUPER_ADMIN',
      permissions: defaultPermissions,
    },
    ...ROLES.map((e, i) => ({
      id: Number(i) + 3,
      type: e.name,
      permissions: defaultPermissions.map(z => (e.permissions.includes(z.can) ? z : null)).filter(x => x !== null),
    })),
  ];
}
