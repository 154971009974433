/* eslint-disable no-unreachable */
import { getCookie } from 'helpers/common';
import React, { useEffect, useState } from 'react';
import backendService from 'services/backendService';
import io from 'socket.io-client';
import { AuthContext } from './authContext';
import { createContextHook, useContextHook } from 'use-context-hook';

export const SocketContext = createContextHook();

let socket = {};

export const SocketContextProvider = props => {
  const { isLoggedIn, hasPermission, user } = useContextHook(AuthContext, {
    isLoggedIn: 1,
    hasPermission: 1,
    user: 1,
  });
  const [currentAERoom, setCurrenAERoom] = useState('');
  const [bm, setBm] = useState(1);
  const { services_data } = backendService.GetServices(isLoggedIn && hasPermission('services.nav'));
  const [services, setServices] = useState([]);

  let beeps = null;
  useEffect(() => {
    console.log({ cookies: localStorage.getItem('cookie'), token: getCookie('token') });
    socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
      allowEIO3: true,
      secure: true,
      multiplex: false,
      path: '/ws/websockets',
      auth: {
        token: getCookie('admin_token'),
      },
    });
  }, [isLoggedIn]);

  useEffect(() => {
    if (services.map(({ status }) => status).includes(false)) {
      const sound = new Audio('https://assets.mixkit.co/sfx/download/mixkit-censorship-beep-1082.wav');
      beeps = setInterval(() => {
        window.dispatchEvent(new Event('mousemove'));
        sound.volume = 0.1;
        sound.play();
      }, 1000);
      setTimeout(() => {
        clearInterval(beeps);
      }, 5000);
    }
    return () => {
      clearInterval(beeps);
    };
  }, [services]);

  useEffect(() => {
    setServices(services_data);
  }, [services_data]);

  const onStream = async e => {
    const { data: _ } = e.detail;
    setServices(__ => {
      const _data = [...__];
      const index = _data.findIndex(({ src }) => src === _.src);
      _data[index] = _;
      return _data;
    });
  };

  useEffect(() => {
    window.addEventListener('SERVICE_EVENT_STATE_UPDATE', onStream);
    return () => {
      window.removeEventListener('SERVICE_EVENT_STATE_UPDATE', onStream);
    };
  }, []);

  const rooms = {
    roomB3AE: [
      '1-1-1-AE',
      '7-1-1-AE',
      '12-1-1-AE',
      '13-1-1-AE',
      '14-1-1-AE',
      '17-1-1-AE',
      '18-1-1-AE',
      '20-1-1-AE',
      '31-1-1-AE',
      '32-1-1-AE',
      '91-1-1-AE',
    ],
    roomB3AEN: [
      '1-1-2-AE',
      '7-1-2-AE',
      '12-1-2-AE',
      '13-1-2-AE',
      '14-1-2-AE',
      '17-1-2-AE',
      '18-1-2-AE',
      '20-1-2-AE',
      '31-1-2-AE',
      '32-1-2-AE',
      '91-1-2-AE',
    ],
    roomB3CAE: [
      '1-1-1-AE',
      '7-1-1-AE',
      '12-1-1-AE',
      '13-1-1-AE',
      '14-1-1-AE',
      '17-1-1-AE',
      '18-1-1-AE',
      '20-1-1-AE',
      '31-1-1-AE',
      '32-1-1-AE',
      '91-1-1-AE',
    ],
    roomBwAE: [
      '1-11-1-AE',
      '7-11-1-AE',
      '12-11-1-AE',
      '13-11-1-AE',
      '14-11-1-AE',
      '17-11-1-AE',
      '18-11-1-AE',
      '20-11-1-AE',
      '31-11-1-AE',
      '32-11-1-AE',
      '91-11-1-AE',
    ],
    roomB4AE: [
      '1-3-1-AE',
      '7-3-1-AE',
      '12-3-1-AE',
      '13-3-1-AE',
      '14-3-1-AE',
      '17-3-1-AE',
      '18-3-1-AE',
      '20-3-1-AE',
      '31-3-1-AE',
      '32-3-1-AE',
      '91-3-1-AE',
    ],
    roomB5AE: [
      '1-5-1-AE',
      '7-5-1-AE',
      '12-5-1-AE',
      '13-5-1-AE',
      '14-5-1-AE',
      '17-5-1-AE',
      '18-5-1-AE',
      '20-5-1-AE',
      '31-5-1-AE',
      '32-5-1-AE',
      '91-5-1-AE',
    ],
    roomDAE: ['344-1-2-AE'],
    roomGFAE: ['1-777-1-AE'],
    roomHAE: ['34-1-2-AE'],
    serviceStatus: ['serviceStatus'],
  };

  const LeaveRoom = async room => {
    if (!socket.connected) await socket.connect();
    socket.emit('leave', {
      rooms: rooms[room],
    });
    if (room !== 'serviceStatus') setCurrenAERoom(() => '');
    return null;
  };

  const JoinRoom = async room => {
    if (!socket.connected) await socket.connect();
    if (currentAERoom !== 'serviceStatus' && currentAERoom) {
      LeaveRoom(currentAERoom);
    }
    socket.emit('join', {
      rooms: rooms[room],
    });
    if (room !== 'serviceStatus') setCurrenAERoom(() => room);
  };

  useEffect(() => {
    if (isLoggedIn) {
      socket.connect();
      socket.on('connect', () => {
        console.log('connected');
        socket.on('UPDATE', msg => {
          const { data, isCid, room } = msg;
          if (room === 'serviceStatus') {
            window.dispatchEvent(new CustomEvent('SERVICE_EVENT_STATE_UPDATE', { detail: { data, type: 'SE' } }));
          } else if (!isCid) {
            window.dispatchEvent(new CustomEvent('AE_EVENT_STATE_UPDATE', { detail: { data, type: 'AE' } }));
          } else {
            window.dispatchEvent(new CustomEvent('UE_EVENT_STATE_UPDATE', { detail: { data, type: 'UE' } }));
          }
        });
        if (isLoggedIn && hasPermission('services.nav'))
          setTimeout(() => {
            console.log('subscribed to services');
            LeaveRoom('serviceStatus').then(() => {
              JoinRoom('serviceStatus');
            });
          }, 5000);
      });
      socket.on('disconnect', () => {
        console.log('disconnected');
      });
    }
    return () => {
      socket.disconnect();
    };
  }, [isLoggedIn, user?.permissions]);

  const subscribeCID = async CID => {
    socket.emit('join:cid', {
      CID,
    });
  };

  const unsubscribeCID = async CID => {
    if (!socket.connected) await socket.connect();
    socket.emit('leave:cid', {
      CID,
    });
  };

  const setBM = _bm => {
    localStorage.setItem('bm', _bm);
    setBm(() => _bm);
  };

  useEffect(() => {
    localStorage.setItem('bm', 1);
  }, []);

  return (
    <SocketContext.Provider
      value={{
        rooms,
        socket,
        JoinRoom,
        LeaveRoom,
        subscribeCID,
        unsubscribeCID,
        bm,
        setBM,
        services,
        setServices,
        service_error: services.map(({ status }) => status).includes(false),
      }}>
      {props.children}
    </SocketContext.Provider>
  );
};
