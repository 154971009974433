/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';

import React, { useEffect, useState } from 'react';
import { endOfMonth } from 'date-fns';
import { CenteredContainer } from 'styles/App.styles';
import Grid from 'components/atoms/Grid';
import { Col, Day, Row } from './multidatepicker.styles';

export default function MultiDatePicker({ value: days = [], onChange = () => {}, name, error }) {
  const [total, setTotal] = useState([]);
  useEffect(() => {
    const totalDays = [];
    const totalDaysInMonth = endOfMonth(new Date()).getDate();
    for (let i = 0; i < totalDaysInMonth; i++) {
      totalDays.push({
        date: String(i + 1),
      });
    }
    const weeks = [];
    let week = [];
    let i = 0;
    while (i < totalDays.length) {
      week.push(totalDays[i]);
      if (week.length === 7) {
        weeks.push(week);
        week = [];
      }
      i++;
    }
    if (week.length) {
      weeks.push(week);
    }
    setTotal(weeks);
  }, []);
  return (
    <CenteredContainer>
      <Grid>
        {total.map((week, index) => (
          <Row key={index}>
            {week.map((day, _index) => (
              <Col key={_index}>
                <Day
                  error={error}
                  onClick={() => {
                    const newDays = [...days];
                    if (newDays.includes(day.date)) {
                      newDays.splice(newDays.indexOf(day.date), 1);
                    } else {
                      newDays.push(day.date);
                    }
                    onChange({
                      target: {
                        value: newDays.length ? newDays : null,
                        name,
                      },
                    });
                  }}
                  selected={days.includes(day.date)}>
                  {day.date}
                </Day>
              </Col>
            ))}
          </Row>
        ))}
      </Grid>
    </CenteredContainer>
  );
}
