import { AuthContext } from 'context/authContext';
import { SideNavContext } from 'context/sideNavContext';
import { SocketContext } from 'context/socketContext';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
// eslint-disable-next-line no-unused-vars
import styled, { css } from 'styled-components/macro';
import {
  Li,
  StyledLink,
  Title,
  StyledSubMenu,
  SubMenuItem,
  SubMenuLink,
  SubMenuTitle,
  ArrowHolder,
} from './SideNav.styles';
import { useContextHook } from 'use-context-hook';

function SubMenu({ item, isLoading }) {
  const { toggleSideNav } = useContextHook(SideNavContext, {
    toggleSideNav: 1,
  });
  const [subnav, setSubnav] = useState(false);
  const { allowedPages } = useContextHook(AuthContext, {
    allowedPages: 1,
  });
  const { service_error } = useContextHook(SocketContext, {
    service_error: 1,
  });
  const showSubnav = () => setSubnav(!subnav);

  return (
    item.file !== 'profile' && (
      <Li css={isLoading && 'text-align: center;'}>
        {isLoading ? (
          <Skeleton circle height={40} width={40} css="margin: 0 auto;" />
        ) : (
          <>
            <StyledLink
              to={item.hideSelf ? `/admin/${item.file}/${item?.subNav[0]?.file}` : `/admin/${item.file}`}
              onClick={() => {
                showSubnav();
                toggleSideNav();
              }}>
              <i
                css={`
                  ${({ color }) =>
                    color &&
                    css`
                      color: ${color};
                    `}
                  ${({ blink }) =>
                    blink &&
                    css`
                      animation: blink 1s infinite;
                    `}
                    @keyframes blink {
                    0% {
                      opacity: 1;
                    }
                    50% {
                      opacity: 0;
                    }
                    100% {
                      opacity: 1;
                    }
                  }
                `}
                color={item.file === 'services' && service_error ? 'red' : ''}
                blink={item.file === 'services' && service_error}
                className="icon material-icons-outlined">
                {item.icon}
              </i>
              <Title>{item.name}</Title>
              {item.subNav && subnav ? (
                <ArrowHolder>
                  <span className="material-icons-outlined">arrow_drop_up</span>
                </ArrowHolder>
              ) : item.subNav ? (
                <ArrowHolder>
                  <span className="material-icons-outlined">arrow_drop_down</span>
                </ArrowHolder>
              ) : null}
            </StyledLink>
          </>
        )}
        {subnav && (
          <StyledSubMenu>
            {item?.subNav
              ?.filter(
                ({ file, live }) =>
                  allowedPages.includes(file) &&
                  (window?.process?.env?.REACT_APP_MAIN_URL === 'https://backoffice.geniusfeeds.work' ? live : true),
              )
              .map((subNavItem, index) => (
                <SubMenuItem key={index}>
                  <SubMenuLink to={`/admin/${item?.file}/${subNavItem?.file}`}>
                    <i className="icon material-icons-outlined">{subNavItem.icon}</i>
                    <SubMenuTitle>{subNavItem.name}</SubMenuTitle>
                  </SubMenuLink>
                </SubMenuItem>
              ))}
          </StyledSubMenu>
        )}
      </Li>
    )
  );
}

export default SubMenu;
