/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
// import backendService from 'services/backendService';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import MultiDatePicker from 'components/molecules/MultiDatePicker';
import Select from 'components/atoms/Select';
import backendService from 'services/backendService';
import Field from 'components/molecules/Field';
import { useContextHook } from 'use-context-hook';

function ShiftsForm({ shift, onClose = () => {}, viewOnly }) {
  const [form] = useForm();
  const [state, setState] = useState({
    days: [],
  });
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, {
    refetch: 1,
  });
  const { admins_data, admins_loading } = backendService.GetAdminOptions('BACK_OFFICE');

  useEffect(() => {
    if (shift) {
      const obj = {};
      shift.timing.forEach(({ dates, startTime, endTime, admin }, i) => {
        obj[`worker-${i}`] = { value: admin._id, label: admin.username };
        obj[`startTime-${i}`] = { value: startTime, label: startTime };
        obj[`endTime-${i}`] = { value: endTime, label: endTime };
        obj.days = dates;
      });
      form.setFieldsValue({
        shift_name: shift.shift,
        no_of_shifts: { value: shift.timing.length, label: shift.timing.length },
        ...obj,
      });
      setState({
        shift_name: shift.shift,
        no_of_shifts: { value: shift.timing.length, label: shift.timing.length },
        ...obj,
      });
    }
  }, [shift]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const obj = {};
      Object.entries(state).forEach(([key, value]) => {
        if (key.includes('worker')) {
          const main = key.split('worker-')[1];
          if (!obj[main]) {
            obj[main] = {
              admin: value.value,
              dates: state.days,
            };
          } else {
            obj[main].admin = value.value;
            obj[main].dates = state.days;
          }
        }
        if (key.includes('startTime')) {
          const main = key.split('startTime-')[1];
          if (!obj[main]) {
            obj[main] = {
              startTime: value.value,
            };
          } else {
            obj[main].startTime = value.value;
          }
        }
        if (key.includes('endTime')) {
          const main = key.split('endTime-')[1];
          if (!obj[main]) {
            obj[main] = {
              endTime: value.value,
            };
          } else {
            obj[main].endTime = value.value;
          }
        }
      });

      const payload = {
        shift: state.shift_name,
        timing: Object.values(obj),
      };
      if (shift) {
        await backendService.updateShift(shift._id, payload);
      } else {
        await backendService.createShift(payload);
      }
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: 'Shift saved successfully',
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  const timeOptions = [
    {
      value: '00:00',
      label: '00:00',
    },
    {
      value: '00:30',
      label: '00:30',
    },
    {
      value: '01:00',
      label: '01:00',
    },
    {
      value: '01:30',
      label: '01:30',
    },
    {
      value: '02:00',
      label: '02:00',
    },
    {
      value: '02:30',
      label: '02:30',
    },
    {
      value: '03:00',
      label: '03:00',
    },
    {
      value: '03:30',
      label: '03:30',
    },
    {
      value: '04:00',
      label: '04:00',
    },
    {
      value: '04:30',
      label: '04:30',
    },
    {
      value: '05:00',
      label: '05:00',
    },
    {
      value: '05:30',
      label: '05:30',
    },
    {
      value: '06:00',
      label: '06:00',
    },
    {
      value: '06:30',
      label: '06:30',
    },
    {
      value: '07:00',
      label: '07:00',
    },
    {
      value: '07:30',
      label: '07:30',
    },
    {
      value: '08:00',
      label: '08:00',
    },
    {
      value: '08:30',
      label: '08:30',
    },
    {
      value: '09:00',
      label: '09:00',
    },
    {
      value: '09:30',
      label: '09:30',
    },
    {
      value: '10:00',
      label: '10:00',
    },
    {
      value: '10:30',
      label: '10:30',
    },
    {
      value: '11:00',
      label: '11:00',
    },
    {
      value: '11:30',
      label: '11:30',
    },
    {
      value: '12:00',
      label: '12:00',
    },
    {
      value: '12:30',
      label: '12:30',
    },
    {
      value: '13:00',
      label: '13:00',
    },
    {
      value: '13:30',
      label: '13:30',
    },
    {
      value: '14:00',
      label: '14:00',
    },
    {
      value: '14:30',
      label: '14:30',
    },
    {
      value: '15:00',
      label: '15:00',
    },
    {
      value: '15:30',
      label: '15:30',
    },
    {
      value: '16:00',
      label: '16:00',
    },
    {
      value: '16:30',
      label: '16:30',
    },
    {
      value: '17:00',
      label: '17:00',
    },
    {
      value: '17:30',
      label: '17:30',
    },
    {
      value: '18:00',
      label: '18:00',
    },
    {
      value: '18:30',
      label: '18:30',
    },
    {
      value: '19:00',
      label: '19:00',
    },
    {
      value: '19:30',
      label: '19:30',
    },
    {
      value: '20:00',
      label: '20:00',
    },
    {
      value: '20:30',
      label: '20:30',
    },
    {
      value: '21:00',
      label: '21:00',
    },
    {
      value: '21:30',
      label: '21:30',
    },
    {
      value: '22:00',
      label: '22:00',
    },
    {
      value: '22:30',
      label: '22:30',
    },
    {
      value: '23:00',
      label: '23:00',
    },
    {
      value: '23:30',
      label: '23:30',
    },
  ];

  return (
    <Form
      onSubmit={onSubmit}
      form={form}
      onTouched={_ => {
        setState(__ => ({ ...__, ..._ }));
      }}
      onError={() => {
        Toast({
          type: 'error',
          message: 'Please fill all the required fields',
        });
      }}>
      <Grid
        xs={1}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          name="days"
          rules={[
            {
              required: true,
            },
          ]}>
          <MultiDatePicker />
        </Form.Item>
        <Form.Item
          type="text"
          label="Shift Name"
          name="shift_name"
          rules={[
            {
              required: true,
            },
          ]}>
          <Field disabled={viewOnly} />
        </Form.Item>
        <Form.Item
          type="text"
          name="no_of_shifts"
          label="Number of shifts"
          options={[
            { label: '1', value: '1' },
            { label: '2', value: '2' },
            { label: '3', value: '3' },
            { label: '4', value: '4' },
            { label: '5', value: '5' },
          ]}
          rules={[
            {
              required: true,
            },
          ]}>
          <Select disabled={viewOnly} />
        </Form.Item>
        {state?.no_of_shifts &&
          new Array(Number(state.no_of_shifts.value)).fill(0).map((_, i) => (
            <Grid
              xs={3}
              colGap={20}
              css={`
                align-items: center;
              `}>
              <Form.Item
                type="text"
                label="Start Time"
                name={`startTime-${i}`}
                options={timeOptions}
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select disabled={viewOnly} />
              </Form.Item>
              <Form.Item
                type="text"
                label="Finish Time"
                options={timeOptions}
                name={`endTime-${i}`}
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select disabled={viewOnly} />
              </Form.Item>
              <Form.Item
                loading={admins_loading}
                options={admins_data}
                label="Worker"
                name={`worker-${i}`}
                rules={[
                  {
                    required: true,
                  },
                ]}>
                <Select disabled={viewOnly} />
              </Form.Item>
            </Grid>
          ))}
      </Grid>
      {!viewOnly && (
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          css={`
            margin-top: 20px;
          `}>
          Save
        </Button>
      )}
    </Form>
  );
}

export default ShiftsForm;
