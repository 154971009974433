import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/login';
import Pages from './pages';
import { AuthContext } from './context/authContext';
import { useContextHook } from 'use-context-hook';

function PublicRoute({ children, redirectTo, isLoggedIn }) {
  return isLoggedIn ? <Navigate to={redirectTo} /> : children;
}

function PrivateRoute({ children, redirectTo, isLoggedIn }) {
  return isLoggedIn ? children : <Navigate to={redirectTo} />;
}

function Router() {
  const { isLoggedIn } = useContextHook(AuthContext, {
    isLoggedIn: 1,
  });

  return (
    <Routes>
      <Route
        path="/admin/:view/:child?"
        element={
          <PrivateRoute redirectTo="/" isLoggedIn={isLoggedIn}>
            <Pages />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <PublicRoute isLoggedIn={isLoggedIn} redirectTo="/admin/pre-live">
            <Login />
          </PublicRoute>
        }
      />
    </Routes>
  );
}

export default Router;
