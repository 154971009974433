/* eslint-disable no-shadow */
import React, { lazy, useState, useEffect, Suspense } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loaders from 'components/atoms/Loaders';
import PageTemplate from '../components/templates/PageTemplate';
import { AuthContext } from '../context/authContext';
import pages from '../nav.json';
import { useContextHook } from 'use-context-hook';

const importView = file =>
  // eslint-disable-next-line no-console
  lazy(() => import(`./${file}`).catch(err => console.log(`Error in importing ${file}`, { err })));

export default function Index() {
  const { allowedPages, loading_user } = useContextHook(AuthContext, {
    allowedPages: 1,
    loading_user: 1,
  });
  const { view, child } = useParams();
  const metaViewData = pages;
  const history = useNavigate();
  const [selectedView, setSelectedView] = useState([]);
  async function loadView(filtered) {
    const promise = filtered.map(async _view => {
      const View = await importView(_view.file);
      // eslint-disable-next-line no-use-before-define
      return <View key={_view.id} selectView={selectView} />;
    });
    Promise.all(promise).then(setSelectedView);
  }

  async function selectView(file) {
    const filtered = metaViewData
      .map(({ subNav = [], ...item }) => [item, ...subNav])
      .flat(Infinity)
      .filter(elem => elem.file === file);
    loadView([filtered[0]]);
  }

  useEffect(() => {
    let fileToLoad = view;
    if (child) {
      fileToLoad = child;
    }
    if (!allowedPages.includes(fileToLoad)) {
      fileToLoad = allowedPages.length > 0 ? allowedPages[0] : 'pre-live';
    }
    if (
      !metaViewData
        .filter(({ live }) =>
          window?.process?.env?.REACT_APP_MAIN_URL === 'https://backoffice.geniusfeeds.work' ? live : true,
        )
        .map(({ file, subNav = [] }) => [
          file,
          ...subNav
            .filter(({ live }) =>
              window?.process?.env?.REACT_APP_MAIN_URL === 'https://backoffice.geniusfeeds.work' ? live : true,
            )
            .map(({ file }) => file),
        ])
        .flat(Infinity)
        .includes(fileToLoad) ||
      !fileToLoad ||
      fileToLoad === 'null' ||
      fileToLoad === 'admin/null'
    ) {
      fileToLoad = 'pre-live';
    }
    if (allowedPages.length === 1 && allowedPages[0] === 'no-permissions') {
      fileToLoad = 'no-permissions';
    }
    if (child) {
      history(`/admin/${view}/${fileToLoad}`, { replace: true });
    } else history(`/admin/${fileToLoad}`, { replace: true });
    selectView(fileToLoad);
  }, [view, child, allowedPages, loading_user, metaViewData]);

  return (
    <>
      {loading_user ? (
        <Loaders pageLoader />
      ) : (
        <PageTemplate
          title={view}
          showTemplate={metaViewData?.filter(elem => elem.file === view)[0]?.navigations}
          topBar>
          <Suspense fallback={<Loaders viewLoader />}>{selectedView}</Suspense>
        </PageTemplate>
      )}
    </>
  );
}
